import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { ListLoading, ProductItem, ProductListBoxMin, ProductListPage } from "component/product/items";
import { NotItems } from "component/basic/notItems";
import { bottomChk } from "js/function";
import { FixedSection } from "component/basic";
import { FilterArea, FilterMoSection, FilterMoTap, FilterSection, FilterSh, FilterTapItem } from "component/product/Filter";
import { Tap } from "component/basic/tap";
import { BasicSelect } from "component/basic/formItems";

const MarketList = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const locationData = location?.state ? location?.state : null;
    const setId = locationData?.setId;

    const [page,setPage] =  useState(1);
    const [category,setCategory] =  useState(setId ? setId : null);
    const [order,setOrder] =  useState("recent");
    const [search,setSearch] =  useState(null);
    const [searchText,setSearchText] =  useState(null);

    const [itemList,setItemList] =  useState([]);
    const [listLoading,setListLoading] =  useState(false);

    const [categoryAllCount,setCategoryAllCount] =  useState(0);
    
    let lastPage = false;
    let settingChk = 0;

    const categoryApi = useGet({
        url:`/product/category`,
        loginType:"login"
    });
    const categoryApiData = categoryApi?.data?.product_categories;

    const pageDataApi = useGet({
        url:`/product?page=${page}&product_category_id=${category ? category : ""}&search=${search ? search : ""}&order=${order}`,
        loginType:"login"
    });
    const pageData = pageDataApi?.data?.products;

    useEffect(() => {
        setItemList([]);
    }, [category,order,search]);

    function addPage(){
        setListLoading(true);
        setPage(page + 1);
        settingChk = 1;
        setTimeout(() => {
            settingChk = 0;
        }, 300);
    };

    useEffect(() => {
        if(pageData?.data && pageData?.data.length > 0){
            if(page == 1){
                setItemList([...pageData?.data]);
            }else{    
                setItemList(prev => [...prev, ...pageData?.data]);
            }
        }else{
            setItemList([]);
        }

        lastPage = pageData?.current_page < pageData?.last_page;

        setListLoading(false);

        window.removeEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [pageDataApi]);

    function handleScroll(){
        if(bottomChk() && lastPage && settingChk == 0){
            addPage();
        }
    };

    useEffect(() => {
        let count = 0;
        if(categoryApiData){
            if(categoryApiData?.length > 0){
                categoryApiData?.forEach(function(item,i){
                    count = count + Number(item.product_count);

                    if(categoryApiData?.length - 1 === i){
                        setCategoryAllCount(count);
                    }
                });
            }else{
                setCategoryAllCount(count);
            }
        }else{
            setCategoryAllCount(0);
        }
    }, [categoryApi]);

    useEffect(() => {
        setItemList([]);
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if(document.querySelector("#vreviewScript")){
            document.querySelector("#vreviewScript").remove();
        }

        const scriptItem = document.createElement("script");
        scriptItem.id = "vreviewScript";
        scriptItem.src = `https://script.vreview.tv/vreview.widget.js?vrid=${process.env.REACT_APP_VREVIEW_ID}`;
        document.head.appendChild(scriptItem);
        
        return () => {
            if(scriptItem){
                scriptItem.remove();
            }
        };
    }, [itemList]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <ProductListPage>
                    <FixedSection
                        pageBox="pageSizing"
                        boxAddClass="productList_fixedsection pcContents"
                        addClass="productList_fixedArea"
                        contentsBox="productListPage"
                        headerChk={true}
                        footerChk={true}
                        minSize={961}
                    >
                        <FilterSection>
                            <FilterArea title="식단 검색">
                                <FilterSh
                                    boxAddClass=""
                                    addClass=""
                                    value={searchText}
                                    placeholder="상품 이름, 재료 검색"
                                    regexp={null}
                                    func={(val)=>{setSearchText(val)}}
                                    btnFunc={()=>{setSearch(searchText)}}
                                    readOnly={false}
                                    disabled={false}
                                />
                            </FilterArea>
                            {categoryApiData && categoryApiData?.length > 0 ? 
                                <FilterArea title="식단 카테고리">
                                    <FilterTapItem
                                        addClass=""
                                        id={null}
                                        val={category}
                                        name={"전체"}
                                        subData={`${categoryAllCount} 개`}
                                        func={()=>{setCategory(null)}}
                                    />
                                    {categoryApiData?.map((item,i)=>(                                    
                                        <FilterTapItem
                                            key={i}
                                            addClass=""
                                            id={item.id}
                                            val={category}
                                            name={item.name}
                                            subData={`${item.product_count} 개`}
                                            func={()=>{setCategory(item.id)}}
                                        />
                                    ))}
                                </FilterArea>
                            :""}
                            <FilterArea title="정렬 순서">
                                <FilterTapItem
                                    addClass=""
                                    id={"recent"}
                                    val={order}
                                    name={"최신 상품 순"}
                                    subData={null}
                                    func={()=>{setOrder("recent")}}
                                />
                                <FilterTapItem
                                    addClass=""
                                    id={"view"}
                                    val={order}
                                    name={"조회 많은 순"}
                                    subData={null}
                                    func={()=>{setOrder("view")}}
                                />
                                <FilterTapItem
                                    addClass=""
                                    id={"review"}
                                    val={order}
                                    name={"리뷰 많은 순"}
                                    subData={null}
                                    func={()=>{setOrder("review")}}
                                />
                                <FilterTapItem
                                    addClass=""
                                    id={"like"}
                                    val={order}
                                    name={"인기 순"}
                                    subData={null}
                                    func={()=>{setOrder("like")}}
                                />
                            </FilterArea>
                        </FilterSection>
                    </FixedSection>
                    <FilterMoSection
                        title="식단 구매하기"
                        shType={true}
                        shValue={searchText}
                        shPlaceholder="상품 이름, 재료 검색"
                        shFunc={(val)=>{setSearchText(val)}}
                        shBtnFunc={()=>{setSearch(searchText)}}
                    >
                        <FilterMoTap>
                            {categoryApiData && categoryApiData?.length > 0 ? 
                                <Tap
                                    addClass=""
                                    allText="전체"
                                    data={categoryApiData}
                                    nameKey="name"
                                    valKey="id"
                                    val={category}
                                    func={(name,val)=>{setCategory(val)}}
                                />
                            :""}
                            <BasicSelect
                                options={[
                                    {
                                        val:"recent",
                                        name:"최신 상품 순"
                                    },
                                    {
                                        val:"view",
                                        name:"조회 많은 순"
                                    },
                                    {
                                        val:"review",
                                        name:"리뷰 많은 순"
                                    },
                                    {
                                        val:"like",
                                        name:"인기 순"
                                    }
                                ]}
                                chChk={order}
                                defaultValue={order}
                                func={(val)=>{setOrder(val)}}
                            />
                        </FilterMoTap>
                    </FilterMoSection>
                    {itemList && itemList?.length > 0 ? 
                        <ProductListBoxMin addClass="">
                            {itemList && itemList?.map((item, i) => (
                                <ProductItem
                                    key={i}
                                    type="market"
                                    id={item.id}
                                    image_url={item.main_image_url}
                                    product_name={item.name}
                                    summary={item.summary}
                                    price={item.price}
                                    discount_price={item.discount_price}
                                    review={true}
                                />
                            ))}
                        </ProductListBoxMin>
                    :
                        <NotItems addClass="productListPage_notItems" text={`조건에 맞는 상품 목록이 없습니다.`}/>
                    }
                </ProductListPage>
                <ListLoading view={listLoading}/>
            </ContentsSection>
            <Footer pcOnly={true}/>
        </>
    );
};

export default MarketList;