import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { TermsContents, TermsContentsBox, TermsTitle } from "component/terms";

const Terms = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const termsApi = useGet({
        url:`/terms`,
    });
    const termsData = termsApi?.data?.terms ? termsApi?.data?.terms?.filter((el)=>Number(el.id) === Number(id)) : null;

    useEffect(() => {
        if(termsData && termsData?.length <= 0){
            navigate("/pageErr");
        }
    }, [termsApi]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
            >
                <Header
                    subLinkType="care"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <TermsContentsBox addClass="pageType">
                    <TermsTitle title={termsData ? termsData[0]?.title : ""}/>
                    <TermsContents contents={termsData ? termsData[0]?.contents : ""}/>
                </TermsContentsBox>
            </ContentsSection>
            <Footer/>
        </>
    );
};

export default Terms;