function OrderPage(data){
    return (
        <div className={`orderPage ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function OrderSection(data){
    return (
        <div className={`orderSection ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function OrderArea(data){
    return (
        <div className={`orderArea ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function OrderAreaTitleBox(data){
    return (
        <div className={`orderArea_titleBox ${data.addClass||""}`}>
            {data.title ? <h1 className="orderArea_title" dangerouslySetInnerHTML={{__html:data.title}}/> : ""}
            {data.children ? 
            <div className="orderArea_title_subItemBox">
                {data.children}
            </div>
            :""}
        </div>
    );
}

function OrderAreaTitleBtn(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`orderArea_btn ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.text}}></button>
    );
}

function CartDel(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`cartDel ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.text}}></button>
    );
}

function OrderProduct(data){
    return (
        <div className="orderProductItem">
            <div className="orderProductItem_img backgroundImg" style={{backgroundImage:`url('${data.image_url}')`}}></div>
            <div className="">
                <h1 className="orderProductItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
                {data.options ? <p className="orderProductItem_options" dangerouslySetInnerHTML={{__html:`${data.options}${data.qty ? ` * ${data.qty}` : ""}`}}/> : ""}
                {data.price ? <p className="orderProductItem_priceListItem" dangerouslySetInnerHTML={{__html:`${data.price}`}}/> : ""}
            </div>
        </div>
    );
}

function OrderProductPrice(data){
    return (
        <div className="orderProductItem_priceBox">
            <h1 className="orderProductItem_price oTextColor" dangerouslySetInnerHTML={{__html:data.discountPrice ? data.discountPrice : data.price}}/>
            {data.discountPrice ? <p className="orderProductItem_basicPrice" dangerouslySetInnerHTML={{__html:data.price}}/> : ""}
        </div>
    );
}

function OrderProductQty(data){
    return (
        <p className={`orderProductQty ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.qty}}/>
    );
}

function NotRemainAmountText(data){
    return (
        <h1 className="notRemainAmountText" dangerouslySetInnerHTML={{__html:data.text}}/>
    );
}

function OrderFixedItemTextBox(data){
    return (
        <div className={`orderFixedItemTextBox ${data.addClass||""}`}>
            <p className={`orderFixedItemTextName ${data.textColorClass}`} dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className={`orderFixedItemTextVal ${data.textColorClass}`} dangerouslySetInnerHTML={{__html:data.val}}/>
        </div>
    );
}

function OrderFixedItemBox(data){
    return (
        <div className={`orderFixedItemBox ${data.addClass||""}`}>
            {data.name ? <h1 className="orderFixedItemBox_name" dangerouslySetInnerHTML={{__html:data.name}}/> : ""}
            {data.children}
        </div>
    );
}

function OrderFixedSubItemBox(data){
    return (
        <div className={`orderFixedSubItemBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function OrderTablePrictBox(data){
    return (
        <div className={`orderTablePrictBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function OrderTermsBox(data){
    return (
        <div className={`orderTermsBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function OrderInputBox(data){
    return (
        <div className={`orderInputBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function OrderTermsText(data){
    return (
        <div className={`orderTermsTextBox ${data.addClass||""}`}>
            <div className="orderTermsTextItem">
                <span/>
                {data.text ? <p className="orderTermsText" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
            </div>
            {data.chkFunc ? <p className="chkBoxBtn" onClick={(e) => data.chkFunc(e)}>{data.chkBtnText ? data.chkBtnText : "보기"}</p> : ""}
        </div>
    );
}

export {OrderPage,OrderSection,CartDel,OrderProduct,OrderProductPrice,OrderProductQty,OrderInputBox,OrderFixedItemTextBox,OrderFixedItemBox,NotRemainAmountText,OrderFixedSubItemBox,OrderTablePrictBox,OrderTermsText,OrderTermsBox,OrderArea,OrderAreaTitleBox,OrderAreaTitleBtn};