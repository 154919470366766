import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { ListLoading, ProductItem, ProductListBoxMin } from "component/product/items";
import { NotItems } from "component/basic/notItems";
import { bottomChk } from "js/function";
import { MypageAreaTitleBox, MypageContents, MypageSection, MypageSideMenu } from "component/mypage/items";

const MyRecipe = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(1);

    const [itemList,setItemList] =  useState([]);
    const [listLoading,setListLoading] =  useState(false);

    let lastPage = false;
    let settingChk = 0;

    const [submitChk,setSubmitChk] =  useState(true);

    const pageDataApi = useGet({
        url:`/recipe?page=${page}&my=1`,
        loginType:"login"
    });
    const pageData = pageDataApi?.data?.recipes;

    function likeFunc(id){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();

            formData.append("type", "recipe");
            formData.append("target_id", id);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: "/like",
                success: (data) => {
                    let oldData = [...itemList];
                    if(oldData?.filter((el) => el.id == id)[0]){
                        oldData = oldData?.map((subItem) => subItem.id == id ? 
                            {
                                ...subItem,
                                is_like: subItem.is_like == 0 ? 1 : 0,
                                like_count: subItem.is_like == 0 ? Number(subItem.like_count) + 1 : Number(subItem.like_count) - 1
                            } 
                        : subItem)
                    }
                    setItemList(oldData);

                    setSubmitChk(true);
                },
                err: (data) => {
                    window.errPopupOpen(data);
                    setSubmitChk(true);
                }
            })
        }
    }

    function addPage(){
        setListLoading(true);
        setPage(page + 1);
        settingChk = 1;
        setTimeout(() => {
            settingChk = 0;
        }, 300);
    };

    useEffect(() => {
        if(pageData?.data && pageData?.data.length > 0){
            let settingData = [...pageData?.data];
            settingData = settingData?.map((item) => {
                return {
                    ...item,
                    is_like : 1
                };
            });

            if(page == 1){
                setItemList([...settingData]);
            }else{    
                setItemList(prev => [...prev, ...settingData]);
            }
        }else{
            setItemList([]);
        }

        lastPage = pageData?.current_page < pageData?.last_page;

        setListLoading(false);

        window.removeEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [pageDataApi]);

    function handleScroll(){
        if(bottomChk() && lastPage && settingChk == 0){
            addPage();
        }
    };

    useEffect(() => {
        setItemList([]);
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <MypageContents>
                    <MypageSideMenu tapActive={3}/>
                    <MypageSection>
                        <MypageAreaTitleBox addClass="" title="나의 레시피"/>
                        {itemList && itemList?.length > 0 ? 
                            <ProductListBoxMin addClass="">
                                {itemList && itemList?.map((item, i) => (
                                    <ProductItem
                                        key={i}
                                        type="recipe"
                                        id={item.id}
                                        image_url={item.image_url}
                                        product_name={item.title}
                                        people_number={item.people_number}
                                        cooking_time={item.cooking_time}
                                        main_disease={item.main_disease}
                                        view_count={item.view_count}
                                        like_count={item.like_count}

                                        pageType="myRecipe"
                                        is_like={item.is_like}
                                        likeFunc={(id)=>{likeFunc(id)}}
                                    />
                                ))}
                            </ProductListBoxMin>
                        :
                            <NotItems addClass="mypageList_notItems" text={`좋아요한 레시피 목록이 없습니다.`}/>
                        }
                    </MypageSection>
                </MypageContents>
                <ListLoading view={listLoading}/>
            </ContentsSection>
            <Footer/>
        </>
    );
};

export default MyRecipe;