import { useNavigate } from "react-router-dom";
import { ProductCaption, ProductListCount } from "./items";
import { Fragment, useEffect, useRef, useState } from "react";
import { CustomSelect } from "component/basic/formItems";
import CountInput from "./count";
import { comFormat, slideToggle, slideUp } from "js/function";
import { BtnBox, BtnItem } from "component/basic/btns";
import { DotText } from "component/basic";
import { SurveyDetailNutrition, SurveyDetailTitleBox } from "component/survey/items";

function DetailSection(data){
    return (
        <div className={`detailSection ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function DetailBottomInfoSection(data){
    return (
        <div className={`detailBottomInfoSection ${data.addClass||""}`}>
            <div className="detailBottomInfoArea">
                <div className="detailBottomInfoBox">
                    {data.people_number || data.cooking_time || data.main_disease ? 
                        <div className="productDetailInfo_captionBox">
                            {data.people_number ? <ProductCaption text={data.people_number} addClass="rColor"/> : ""}
                            {data.cooking_time ? <ProductCaption text={`${data.cooking_time}분`} addClass="rColor"/> : ""}
                            {data.main_disease ? <ProductCaption text={data.main_disease} addClass=""/> : ""}
                        </div>
                    :""}
                    <h1 className="detailBottomInfo_name" dangerouslySetInnerHTML={{__html:data.name}}/>
                    {data.price ? 
                        <ProductDetailInfo_priceBox
                            discount_price={data.discount_price}
                            price={data.price}
                        />
                    :""}
                </div>
                {data.btnText ? 
                    <BtnItem
                        addClass="oColor"
                        contents={data.btnText}
                        disabled={false}
                        func={() => {
                            data.btnFunc()
                        }}
                    />
                    :
                    <BtnItem
                        addClass="oColor"
                        contents={"바로 구매하기"}
                        disabled={false}
                        func={() => {
                            window.scrollTo({left: 0, top: 0, behavior: "smooth"});
                        }}
                    />
                }
            </div>
        </div>
    );
}

function ProductDetailInfo_priceBox(data){
    return (
        <div className={`productDetailInfo_priceBox ${data.addClass||""}`}>
            <h2 className="productItem_price" dangerouslySetInnerHTML={{__html:`${data.discount_price ? comFormat(Number(data.price) - Number(data.discount_price)) : comFormat(data.price)}원${data.discount_price ? " ~ " : ""}`}}/>
            {data.discount_price ? 
                <p className="productItem_basicPrice" dangerouslySetInnerHTML={{__html:`${comFormat(data.price)}원`}}/>
            :""}
        </div>
    );
}

function DetailCategoriesItem(data){
    return (
        <>
            {data.data?.map((subItem,idx)=>(
                <div className="detailCategoriesItem" key={idx}>
                    <p className={`detailCategoriesItem_name ${subItem?.is_required === 1 ? "oTextColor" : ""}`} dangerouslySetInnerHTML={{__html:subItem.foodNm||"-"}}/>
                    <p className="detailCategoriesItem_val" dangerouslySetInnerHTML={{__html:subItem.nutConSrtrQua||"-"}}/>
                </div>
            ))}
        </>
    );
}

function DetailCategoriesSection(data){
    return (
        <div className={`detailCategoriesSection ${data.addClass||""}`}>
            {data.data?.map((item,i)=>(
                <div className="detailCategoriesArea" key={i}>
                    <h1 className="detailCategoriesTitle" dangerouslySetInnerHTML={{__html:item.category}}/>
                    {(data.type === "recipe" ? (item.recipe_ingredients && item.recipe_ingredients?.length > 0)
                    : (item.product_ingredients && item.product_ingredients?.length > 0)) ? 
                        <div className="detailCategoriesItemBox">
                            <DetailCategoriesItem data={data.type === "recipe" ? item.recipe_ingredients : item.product_ingredients}/>
                        </div>
                    :""}
                </div>
            ))}
        </div>
    );
}

function DetailMenusSection(data){
    const slideItem = useRef(null);
    const [openChk,setOpenChk] = useState(false);
    const [slideChk,setSlideChk] = useState(true);

    return (
        <div className={`detailMenusSection ${openChk ? "active" : ""}`}>
            <button type="button" onClick={(e)=>{
                if(slideChk){
                    setSlideChk(false);
                    setTimeout(function(){
                        slideToggle(slideItem.current,"flex")
                        setOpenChk(!openChk)
                    },0);
                    setTimeout(() => {
                        setSlideChk(true);
                    }, 300);
                }
            }} className="detailMenus_subInfoTitleBox detailMenus_subInfoItem">
                <div className="detailMenus_subInfoTextBox">
                    <SurveyDetailTitleBox
                        title={`<span class='oTextColor'>${data.data.name}</span>의 영양성분`}
                        subTitle={`레시피에 모든 재료를 사용했을 때 포함된 영양소에요.`}
                    />
                </div>
                <img src="/assets/images/icon/user_menu_icon.svg"/>
            </button>
            <div className="detailMenus_subInfoTextItem detailMenus_subInfoItem" ref={slideItem}>
                <div className="detailMenus_subInfoTextBox">
                    <SurveyDetailNutrition
                        data={[
                            {
                                color: data?.nutrition_config && data?.nutrition_config?.calorie > data.data?.enerc ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"kcal",
                                val:data.data?.enerc,
                                name:"칼로리"
                            },
                            {
                                color: data?.nutrition_config && data?.nutrition_config?.natrium > data.data?.nat ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"mg",
                                val:data.data?.nat,
                                name:"나트륨"
                            },
                            {
                                color: data?.nutrition_config && data?.nutrition_config?.carbohydrate > data.data?.chocdf ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:data.data?.chocdf,
                                name:"탄수화물"
                            },
                            {
                                color: data?.nutrition_config && data?.nutrition_config?.sugar > data.data?.sugar ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:data.data?.sugar,
                                name:"당류"
                            },
                            {
                                color: data?.nutrition_config && data?.nutrition_config?.fat > data.data?.fatce ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:data.data?.fatce,
                                name:"지방"
                            },
                            {
                                color: data?.nutrition_config && data?.nutrition_config?.saturated_fat > data.data?.fasat ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:data.data?.fasat,
                                name:"포화지방"
                            },
                            {
                                color:"oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:data.data?.fatrn,
                                name:"트랜스지방"
                            },
                            {
                                color:"oColor",
                                colorChk:false,
                                type:null,
                                unit:"mg",
                                val:data.data?.chole,
                                name:"콜레스테롤"
                            },
                            {
                                color: data?.nutrition_config && data?.nutrition_config?.protein > data.data?.prot ? "rColor" : "oColor",
                                colorChk:false,
                                type:null,
                                unit:"g",
                                val:data.data?.prot,
                                name:"단백질"
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}

function ProductDetailArea(data){
    return (
        <div className={`productDetailArea ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function ProductOptionSelBox(data){
    return (
        <div className={`productOptionSelBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function DetailCopyText(data){

    return (
        <textarea autoComplete="off" ref={data?.inputRef} defaultValue={window.location.href} className="copyInput"/>
    );
}

function DetailSubBtn(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`detailSubBtn ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.contents}}></button>
    );
}

function DetailSubBtnBox(data){
    return (
        <div className={`detailSubBtnBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <Fragment key={i}>{item.func ? <DetailSubBtn addClass="" func={item.func} contents={item.contents}/> : ""}</Fragment>
            ))}
        </div>
    );
}

function ProductDetailInfo_subTextBox(data){
    return (
        <div className={`productDetailInfo_subTextBox ${data.addClass||""}`}>
            <p className="productDetailInfo_subText" dangerouslySetInnerHTML={{__html:data.name}}/>
            {data.text ? <p className="productDetailInfo_subVal" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
            {data.children}
        </div>
    );
}

function OptionSelItem(data){
    return (
        <div className={`optionSelItem ${data.addClass||""}`}>
            <div className="optionSelItem_nameBox">
                <h1 className="optionSelItem_name" dangerouslySetInnerHTML={{__html:`${data.productName} <span>(${data.name})</span>`}}/>
                <button type="button" onClick={()=>{data.delFunc()}} className="optionSelItem_del">
                    <img src="/assets/images/icon/del.svg"/>
                </button>
            </div>
            <div className="optionSelItem_controll">
                <CountInput
                    val={data.qty}
                    qty={null}
                    optionQty={null}
                    maxQty={data.remain_amount}
                    func={data.countFunc}
                    excessFunc={()=>{}}
                />
                <div className="optionSelItem_priceBox">
                    {data.discount_price ? 
                        <p className="optionSelItem_basicPrice" dangerouslySetInnerHTML={{__html:`${comFormat(data.add_price * data.qty)}원`}}/>
                    :""}
                    <h2 className="optionSelItem_price" dangerouslySetInnerHTML={{__html:`${data.discount_price ? comFormat(data.discount_price * data.qty) : comFormat(data.add_price * data.qty)}원`}}/>
                </div>
            </div>
        </div>
    );
}

function OptionSection(data){
    return (
        <>
        {data.productType === "basic" && data.options && data.options?.length > 0 ? 
        <ProductDetailInfo_subTextBox
            addClass=""
            name="필수 옵션"
            text={null}
        >
            <div className="optiopn_controllBox">
                <CustomSelect
                    addClass="notName productOptionSel"
                    inputName=""
                    placeholder="옵션을 선택해주세요."
                    value={null}
                    func={(name,id,price,remain_amount) => {
                        data.optionSelFunc(name,id,price,remain_amount);
                    }}
                    disabled={false}
                    valKey="id"
                    nameKey="name"
                    subValKey0="add_price"
                    subValKey1="remain_amount"
                    options={data.options}
                />
                {data.optionArr && data.optionArr?.length > 0 ? 
                    <ProductOptionSelBox>
                        {/* {
                            id:0,
                            name:옵션명,
                            add_price:정가
                            discount_price:할인가
                            remain_amount:재고
                            qty:수량
                        } */}
                        {data.optionArr?.map((item,i)=>(
                            <OptionSelItem
                                key={i}
                                addClass=""
                                name={item.name}
                                delFunc={()=>{data.optionDel(item.id)}}
                                qty={item.qty}
                                remain_amount={item.remain_amount}
                                countFunc={(val)=>{data.countFunc(item,val)}}
                                discount_price={item.discount_price}
                                add_price={item.add_price}
                                productName={data.detailType === "market" ? data.name : data.title}
                            />
                        ))}
                    </ProductOptionSelBox>
                :""}
            </div>
        </ProductDetailInfo_subTextBox>
        :""}
        {data.productType === "subscribe" && (data.product_periods && data.product_week_counts && data.product_day_counts) ? 
        <>
            <ProductDetailInfo_subTextBox
                addClass=""
                name="관리기간 선택"
                text={null}
            >
                <div className="optiopn_controllBox">
                    <CustomSelect
                        addClass="notName productOptionSel"
                        inputName=""
                        placeholder="옵션을 선택해주세요."
                        value={data.periodsVal?.id}
                        func={(name,id,price,value) => {
                            data.periodsFunc(name,id,price,value);
                        }}
                        disabled={data?.subscribeOptionChk}
                        valKey="id"
                        nameKey="name"
                        subValKey0="add_price"
                        subValKey1="period_value"
                        options={data?.product_periods}
                    />
                    <DotText text="식단을 구독하시면 영양상담 이용권을 지급해드려요!"/>
                </div>
            </ProductDetailInfo_subTextBox>
            <ProductDetailInfo_subTextBox
                addClass=""
                name="1주 당 식단 수"
                text={null}
            >
                <div className="optiopn_controllBox">
                    <CustomSelect
                        addClass="notName productOptionSel"
                        inputName=""
                        placeholder="옵션을 선택해주세요."
                        value={data.weekCountsVal?.id}
                        func={(name,id,price,value) => {
                            data.weekCountsFunc(name,id,price,value);
                        }}
                        disabled={data?.subscribeOptionChk}
                        valKey="id"
                        nameKey="name"
                        subValKey0="add_price"
                        subValKey1="count"
                        options={data?.product_week_counts}
                    />
                    <DotText text="식단을 구독하시면 영양상담 이용권을 지급해드려요!"/>
                </div>
            </ProductDetailInfo_subTextBox>
            <ProductDetailInfo_subTextBox
                addClass=""
                name="1일 당 끼니 수"
                text={null}
            >
                <div className="optiopn_controllBox">
                    <CustomSelect
                        addClass="notName productOptionSel"
                        inputName=""
                        placeholder="옵션을 선택해주세요."
                        value={data.dayCountsVal?.id}
                        func={(name,id,price,value) => {
                            data.dayCountsFunc(name,id,price,value);
                        }}
                        disabled={data?.subscribeOptionChk}
                        valKey="id"
                        nameKey="name"
                        subValKey0="add_price"
                        subValKey1="count"
                        options={data?.product_day_counts}
                    />
                    <DotText text="1일 2식을 선택하실 경우 동일한 식단이 발송됩니다.<br/>(한 끼 구성 : 어육류(밀키트) + 채소찬(완조리) 2종)"/>
                    {data.optionArr && data.optionArr?.length > 0 ? 
                        <ProductOptionSelBox>
                            {/* {
                                periods:{
                                    id:periods?.id,
                                    value:periods?.value,
                                    price:periods?.price,
                                    discount_price:periods?.discount_price,
                                    name:periods?.name
                                },
                                weekCounts:{
                                    id:weekCounts?.id,
                                    value:weekCounts?.value,
                                    price:weekCounts?.price,
                                    discount_price:weekCounts?.discount_price,
                                    name:weekCounts?.name
                                },
                                dayCounts:{
                                    id:dayCounts?.id,
                                    value:dayCounts?.value,
                                    price:dayCounts?.price,
                                    discount_price:dayCounts?.discount_price,
                                    name:dayCounts?.name
                                },
                                qty:1
                            } */}
                            {data.optionArr?.map((item,i)=>(
                                <OptionSelItem
                                    key={i}
                                    addClass=""
                                    name={`${item?.periods?.value||"-"}주 / ${item?.weekCounts?.name} / ${item?.dayCounts?.name}`}
                                    delFunc={()=>{data.optionDel(item?.periods?.id,item?.weekCounts?.id,item?.dayCounts?.id)}}
                                    qty={item.qty}
                                    remain_amount={null}
                                    countFunc={(val)=>{data.countFunc(item,val)}}
                                    discount_price={item.discount_price}
                                    add_price={item.add_price}
                                    productName={data.detailType === "market" ? data.name : data.title}
                                />
                            ))}
                        </ProductOptionSelBox>
                    :""}
                </div>
            </ProductDetailInfo_subTextBox>
        </>
        :""}
        <ProductDetailInfo_subTextBox
            addClass="paddingItem"
            name={null}
            text={null}
        >
            <div className="productDetailInfo_totalPriceBox">
                <h3 className="productDetailInfo_totalPriceName">총 상품 금액</h3>
                <div className="productDetailInfo_totalPriceItem">
                    {data.discount_totalPrice ? 
                        <p className="productDetailInfo_totalBasicPrice" dangerouslySetInnerHTML={{__html:`${comFormat(data.totalPrice)}원`}}/>
                    :""}
                    <h2 className="productDetailInfo_totalPrice" dangerouslySetInnerHTML={{__html:`${data.discount_totalPrice ? comFormat(data.discount_totalPrice) : comFormat(data.totalPrice)}원`}}/>
                </div>
            </div>
        </ProductDetailInfo_subTextBox>
        </>
    );
}

function ProductDetailInfo_subInfoBox(data){
    const slideItem = useRef(null);
    const [openChk,setOpenChk] = useState(false);
    const [slideChk,setSlideChk] = useState(true);

    useEffect(()=>{
        if(data.id !== data.closeChk){
            slideUp(slideItem.current,300)
            setOpenChk(false)
        }
    },[data.closeChk])

    return (
        <div className={`productDetailInfo_subInfoBox ${openChk ? "active" : ""}`}>
            <button type="button" onClick={(e)=>{
                if(slideChk){
                    setSlideChk(false);
                    data.func(data.id);
                    setTimeout(function(){
                        slideToggle(slideItem.current,"flex")
                        setOpenChk(!openChk)
                    },0);
                    setTimeout(() => {
                        setSlideChk(true);
                    }, 300);
                }
            }} className="productDetailInfo_subInfoTitleBox productDetailInfo_subInfoItem">
                <div className="productDetailInfo_subInfoTextBox">
                    <h1 className="productDetailInfo_subInfoTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
                </div>
                <img src="/assets/images/icon/user_menu_icon.svg"/>
            </button>
            <div className="productDetailInfo_subInfoTextItem productDetailInfo_subInfoItem" ref={slideItem}>
                <div className="productDetailInfo_subInfoTextBox">
                    {data.children}
                </div>
            </div>
        </div>
    );
}

{/* <ProductDetailInfo
    addClass=""
    detailType="market"
    id={productData?.id}
    image_url={productData?.main_image_url}
    category_name={productData?.category_name}
    likeFunc={()=>{likeFunc()}}
    sharingFunc={()=>{sharingFunc()}}
    name={productData?.name}
    summary={productData?.summary}
    price={productData?.price}
    discount_price={productData?.discount_price}

    sale_unit={productData?.sale_unit}
    shelf_life={productData?.shelf_life}
    information={productData?.information}
    payment_information={productData?.payment_information}
    delivery_type={productData?.delivery_type}
    packaging_type={productData?.packaging_type}
    delivery_information={productData?.delivery_information}
    
    options={product_options}
    optionSelFunc={(name,id,price,discount_price,amount)=>{
        id:0,
        name:옵션명,
        add_price:정가
        discount_price:할인가
        remain_amount:재고
        qty:수량
    }}
    
    optionArr={[]}
    optionDel={()=>{}}
    countFunc={()=>{}}
    
    discount_totalPrice={discount_totalPrice}
    totalPrice={totalPrice}
    
    addOrder={()=>{addOrder()}}
    addCart={()=>{addCart()}}
/> */}

{/* <ProductDetailInfo
    addClass=""
    detailType="recipe"
    id={productData?.id}
    image_url={productData?.image_url}
    people_number={productData?.people_number}
    cooking_time={productData?.cooking_time}
    main_disease={productData?.main_disease}

    like={productData?.my_like}

    likeFunc={()=>{likeFunc()}}
    sharingFunc={()=>{sharingFunc()}}

    title={productData?.title}
    contents={productData?.contents}

    view_count={productData?.view_count}
    like_count={productData?.like_count}

    relation_menu_id={productData?.relation_menu_id}
/> */}

function ProductDetailInfo(data){
    const navigate = useNavigate();

    return (
        <div className={`productDetailInfo_section ${data.addClass ? data.addClass : ""}`}>
            <div className="productDetailInfo_img backgroundImg" style={{backgroundImage:`url('${data.image_url}')`}}></div>
            <div className="productDetailInfo_area">
                <div className="productDetailInfo_captionArea">
                    {data.detailType === "recipe" ? 
                        <div className="productDetailInfo_captionBox">
                            {data.people_number ? <ProductCaption text={data.people_number} addClass="rColor"/> : ""}
                            {data.cooking_time ? <ProductCaption text={`${data.cooking_time}분`} addClass="rColor"/> : ""}
                            {data.main_disease ? <ProductCaption text={data.main_disease} addClass=""/> : ""}
                        </div>
                    :""}
                    {data.detailType === "market" || data.detailType === "marketSub" ? 
                        <div className="productDetailInfo_captionBox">
                            <ProductCaption text={data.category_name} addClass=""/>
                        </div>
                    :""}
                    <DetailSubBtnBox
                        addClass="pcContents"
                        data={[
                            {
                                func:data.likeFunc ? ()=>{data.likeFunc()} : null,
                                contents:`${data.like ? "<img src='/assets/images/icon/like_on.svg'/>" : "<img src='/assets/images/icon/like_line.svg'/>"} 좋아요`
                            },
                            {
                                func:data.sharingFunc ? ()=>{data.sharingFunc()} : null,
                                contents:"<img src='/assets/images/icon/sharing.svg'/> 공유하기"
                            }
                        ]}
                    />
                </div>
                <h1 className="productDetailInfo_name" dangerouslySetInnerHTML={{__html:data.detailType === "market" || data.detailType === "marketSub" ? data.name : data.title}}/>
                <p className="productDetailInfo_productText" dangerouslySetInnerHTML={{__html:data.detailType === "market" || data.detailType === "marketSub" ? data.summary : data.contents}}/>

                {data.detailType === "marketSub" ? 
                    <>
                        <div className="productDetailInfo_review">
                            <div vreview-product-id={data.id}></div>
                        </div>
                        <ProductDetailInfo_priceBox
                            discount_price={data.discount_price}
                            price={data.price}
                        />
                    </>
                :""}

                {data.detailType === "market" ? 
                    <>
                        <div className="productDetailInfo_review">
                            <div class="vreview-product-review-summary"></div>
                        </div>
                        <ProductDetailInfo_priceBox
                            discount_price={data.discount_price}
                            price={data.price}
                        />
                        <ProductDetailArea>
                            <ProductDetailInfo_subInfoBox
                                func={()=>{}}
                                title="부가 정보"
                            >
                                <ProductDetailInfo_subTextBox
                                    name="판매단위"
                                    text={data.sale_unit||"-"}
                                />
                                <ProductDetailInfo_subTextBox
                                    name="유통기한"
                                    text={data.shelf_life||"-"}
                                />
                                <ProductDetailInfo_subTextBox
                                    name="안내사항"
                                    text={data.information||"-"}
                                />
                                <ProductDetailInfo_subTextBox
                                    name="결제안내"
                                    text={data.payment_information||"-"}
                                />
                            </ProductDetailInfo_subInfoBox>
                        </ProductDetailArea>
                        <ProductDetailArea>
                            <ProductDetailInfo_subInfoBox
                                func={()=>{}}
                                title="배송 정보"
                            >
                                <ProductDetailInfo_subTextBox
                                    name="배송구분"
                                    text={data.delivery_type||"-"}
                                />
                                <ProductDetailInfo_subTextBox
                                    name="포장타입"
                                    text={data.packaging_type||"-"}
                                />
                                <ProductDetailInfo_subTextBox
                                    name="배송안내"
                                    text={data.delivery_information||"-"}
                                />
                            </ProductDetailInfo_subInfoBox>
                        </ProductDetailArea>
                        <ProductDetailArea addClass="pcContents paddingItem">
                            <OptionSection
                                productType={data.productType}
                                options={data.options}
                                optionSelFunc={data.optionSelFunc}
                                optionArr={data.optionArr}
                                optionDel={data.optionDel}
                                countFunc={data.countFunc}
                                detailType={data.detailType}
                                name={data.name}
                                title={data.title}
                                product_periods={data.product_periods}
                                product_week_counts={data.product_week_counts}
                                product_day_counts={data.product_day_counts}
                                periodsFunc={data.periodsFunc}
                                periodsVal={data.periodsVal}
                                weekCountsVal={data.weekCountsVal}
                                weekCountsFunc={data.weekCountsFunc}
                                dayCountsVal={data.dayCountsVal}
                                dayCountsFunc={data.dayCountsFunc}
                                totalPrice={data.totalPrice}
                                discount_totalPrice={data.discount_totalPrice}
                                subscribeOptionChk={data?.subscribeOptionChk}
                            />
                        </ProductDetailArea>
                        <ProductDetailArea addClass="pcContents">
                            <BtnBox
                                boxType="fixed"
                                addClass={`pcBasicBtnBox ${data.productType === "subscribe" ? "" : "col2"}`}
                            >
                                <BtnItem
                                    addClass="oColor "
                                    contents={"바로 구매하기"}
                                    disabled={false}
                                    func={() => {data.addOrder()}}
                                />
                                {data.productType === "subscribe" ? 
                                ""
                                :
                                <BtnItem
                                    addClass="oColor line"
                                    contents={"장바구니 담기"}
                                    disabled={false}
                                    func={() => {data.addCart()}}
                                />
                                }
                            </BtnBox>
                        </ProductDetailArea>
                    </>
                :""}
                {data.detailType === "recipe" ? 
                    <>
                        <div className="productDetailInfo_countArea">
                            <ProductListCount type="like" text={data.like_count ? comFormat(data.like_count) : 0}/>
                            <ProductListCount type="view" text={data.view_count ? comFormat(data.view_count) : 0}/>
                        </div>
                        {data.relation_menu_id ? <DetailSubBtn addClass="oColor" func={()=>{navigate(`/market/detail/${data.relation_menu_id}`)}} contents={`<img src="/assets/images/icon/detail_order.svg"/>식단으로 구매하기`}/> : ""}
                    </>
                :""}
                {data.detailType === "marketSub" && data.id ? 
                    <DetailSubBtn addClass="oColor" func={()=>{navigate(`/market/detail/${data.id}`)}} contents={`<img src="/assets/images/icon/detail_order.svg"/>식단 구매하러 가기`}/>
                :""}
            </div>
        </div>
    );
}

// product.id -> 상품 id / products.id
// product.type -> 타입 (basic - 1회, subscribe - 구독)
// product.main_image_url -> 이미지 url
// product.category_name -> 카테고리
// product.name -> 상품명
// product.summary -> 요약
// product.grade -> 평점
// product.review_count -> 평점 수
// product.price -> 가격
// product.discount_price -> 할인가격
// product.sale_unit -> 판매 단위
// product.shelf_life -> 유통기한
// product.information -> 안내사항
// product.payment_information -> 결제안내
// product.delivery_type -> 배송구분
// product.packaging_type -> 포장타입
// product.delivery_information -> 배송안내
// product.contents -> 상세설명

// product_options.id -> 상품 옵션 id / product_options.id
// product_options.name -> 옵션명
// product_options.add_price -> 추가금
// product_options.remain_amount -> 잔여 재고


// recipe.id -> 레시피 id / recipes.id
// recipe.image_url -> 이미지 url
// recipe.title -> 제목
// recipe.contents -> 내용
// recipe.people_number -> 식사 인원 수
// recipe.cooking_time -> 소요시간
// recipe.main_disease -> 대표 질환
// recipe.view_count -> 조회수
// recipe.like_count -> 좋아요 수
// recipe.cooking_tip -> 팁 또는 주의사항 안내
// recipe.my_like -> 내 좋아요 여부 (0-X, 1-O)

function RecipeDetailSection(data){
    return (
        <div className={`recipeDetailSection ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function RecipeDetailItem(data){
    return (
        <div className={`recipeDetailItem ${data.addClass||""}`}>
            <div className="recipeDetailItem_img backgroundImg" style={{backgroundImage:`url('${data.main_img}')`}}></div>
            <div className="recipeDetailItem_textArea">
                <h2 className="recipeDetailItem_step" dangerouslySetInnerHTML={{__html:`STEP ${Number(data.idx) + 1}`}}/>
                <p className="recipeDetailItem_text" dangerouslySetInnerHTML={{__html:data.contents}}/>
                <div className="recipeDetailItem_imgSelBox">
                    {data.recipe_detail_images?.map((item,i)=>(
                        <div 
                            className={`recipeDetailItem_imgSel backgroundImg ${data.main_img === item.image_url ? "active" : ""}`} 
                            key={i}
                            style={{backgroundImage:`url('${item.image_url}')`}}
                            onClick={()=>{data.func(item.image_url,data.id)}}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export {DetailSection,DetailCopyText,DetailSubBtnBox,ProductDetailInfo,OptionSection,DetailCategoriesSection,DetailMenusSection,DetailBottomInfoSection,RecipeDetailSection,RecipeDetailItem};