import { useNavigate } from "react-router-dom";
import { useSwiper } from "swiper/react";

function MarketMainBannerControll(data){
    const swiper = useSwiper();
    return (
        <div className="marketMainBannerControll">
            <div className="marketMainBannerControllBox">
                <button className="swiper-button-prev" onClick={() => swiper.slidePrev()}/>
                <button className="swiper-button-next" onClick={() => swiper.slideNext()}/>
            </div>
        </div>
    );
};

function MarketSubBanner(data){
    const navigate = useNavigate();

    return (
        <div className="marketSubBanner">
            {data.data?.map((item,i)=>(
                <div className="marketSubBannerItem backgroundImg" onClick={()=>{navigate("/market/list",{state: {setId:item.id}})}} key={i} style={{backgroundImage:`url('${item.bgImg}')`}}>
                    <div className="marketSubBannerItem_textBox">
                        {item.title ? <h2 className="marketSubBannerItem_title" dangerouslySetInnerHTML={{__html:item.title}}/> : ""}
                        {item.text ? <p className="marketSubBannerItem_text" dangerouslySetInnerHTML={{__html:item.text}}/> : ""}
                    </div>
                    {item.logo ? <img src={`/assets/images/img/${item.logo}.svg`}/> :""}
                </div>
            ))}
        </div>
    );
};

function MarketMainSection(data){
    return (
        <div className={`marketMainSection ${data.addClass||""}`}>
            {data.bgChk ? 
                <div className="marketMainArea">
                    {data.children}
                </div>    
                :
                <>{data.children}</>
            }
        </div>
    );
}

function MarketSectionTitleBox(data){
    return (
        <div className="marketSectionTitleBox">
            <h1 className="marketSectionTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.subTitle ? <p className="marketSectionSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
        </div>
    );
}

function MarketSectionTitleArea(data){
    return (
        <div className="marketSectionTitleArea">
            <MarketSectionTitleBox title={data.title} subTitle={data.subTitle}/>
            {data.children}
        </div>
    );
}

function MarketMainBtnBox(data){
    return (
        <div className={`marketMainBtnBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function MarketMainTap(data){
    return (
        <div className="marketMain_tapBox">
            {data.tap?.map((item,i)=>(
                <button type="button" key={i} onClick={()=>{data.tapFunc(item.id)}} className={`marketMain_tap ${data.tapVal === item.id ? "active" : ""}`} dangerouslySetInnerHTML={{__html:item.name}}/>
            ))}
        </div>
    );
}

export {MarketMainBannerControll,MarketSubBanner,MarketMainSection,MarketSectionTitleBox,MarketMainBtnBox,MarketSectionTitleArea,MarketMainTap}