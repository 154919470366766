import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { MyMenuContents, MyMenuContentsBox, MyMenuTitleBox } from "component/mypage/menu";
import { setDateTime } from "js/function";

const MyMenuDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const myMenuApi = useGet({
        url:`/menu/board/detail?menu_board_id=${id}`,
        loginType:"login"
    });
    const myMenuApiData = myMenuApi?.data?.menu_board;

    useEffect(() => {
        
    }, []);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={false}
                    mMenu={false}
                    pageBack={true}
                    func={()=>{navigate(-1)}}
                    headTitle={"식단표 상세"}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <MyMenuContentsBox>
                    <MyMenuTitleBox
                        title={myMenuApiData?.title}
                        date={myMenuApiData?.created_at ? setDateTime(myMenuApiData?.created_at,"yymmdd","hymd") : "-"}
                    />
                    <MyMenuContents contents={myMenuApiData?.contents}/>
                </MyMenuContentsBox>
            </ContentsSection>
            <Footer/>
        </>
    );
};

export default MyMenuDetail;