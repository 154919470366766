import { DataCaption, TableTd, TableTextValItem, TableTr } from "component/basic";
import { slideToggle, slideUp, typeChk } from "js/function";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";

function SurveyInfo(data){
    const slideItem = useRef(null);
    const [openChk,setOpenChk] = useState(false);

    const [slideChk,setSlideChk] = useState(true);

    useEffect(()=>{
        if(data.id !== data.closeChk){
            slideUp(slideItem.current,300)
            setOpenChk(false)
        }
    },[data.closeChk])

    return (
        <div className={`surveyInfoBox ${openChk ? "active" : ""}`}>
            <button type="button" onClick={(e)=>{
                if(slideChk){
                    setSlideChk(false);
                    data.func(data.id);
                    setTimeout(function(){
                        slideToggle(slideItem.current,"flex")
                        setOpenChk(!openChk)
                    },0);
                    setTimeout(() => {
                        setSlideChk(true);
                    }, 300);
                }
            }} className="surveyInfoTitleBox surveyInfoItem">
                <div className="surveyInfoTextBox">
                    <h1 className="surveyInfoTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
                </div>
                <img src="/assets/images/icon/user_menu_icon.svg"/>
            </button>
            <div className="surveyInfoTextItem surveyInfoItem" ref={slideItem}>
                <div className="surveyInfoTextBox">
                    {data.children ? 
                    data.children : <p className="surveyInfoText" dangerouslySetInnerHTML={{__html:data.text}}/>}
                </div>
            </div>
        </div>
    );
}

function SurveyDetailSection(data){
    return (
        <div className={`surveyDetailSection ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function SurveyDetailTitleBox(data){
    return (
        <div className="surveyDetailTitleBox">
            <h1 className="surveyDetailTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.subTitle ? <p className="surveyDetailSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
        </div>
    );
}

function SurveyDetailUserInfo(data){
    return (
        <div className="surveyDetailUserInfo">
            {data?.data && data?.data?.map((item,i)=>(
                <div className="surveyDetailUserInfoItem" key={i}>
                    <p className="surveyDetailUserInfoName" dangerouslySetInnerHTML={{__html:item.name||"-"}}/>
                    <p className="surveyDetailUserInfoVal" dangerouslySetInnerHTML={{__html:item.val||"-"}}/>
                </div>
            ))}
        </div>
    );
}

function SurveyDetailNutrition(data){
    return (
        <div className="surveyDetailNutrition">
            {data?.data && data?.data?.map((item,i)=>(
                <div className="surveyDetailNutritionItem" key={i}>
                    <div className="surveyDetailNutrition_nameBox">
                        <p className={`surveyDetailNutritionItemName ${item.color === "gColor" || !item.color || !item.colorChk ? "" : item.color}`} dangerouslySetInnerHTML={{__html:item.name||"-"}}/>
                        {item.type ? <DataCaption color={item.color === "" ? "gColor" : item.color} type={item.type}/> : ""}
                    </div>
                    <p className={`surveyDetailNutritionItemVal ${item.color === "gColor" || !item.color ? "" : item.color}`} dangerouslySetInnerHTML={{__html:`${item.val||"-"}${item.unit ? ` <span>${item.unit}</span>` : ""}`}}/>
                </div>
            ))}
        </div>
    );
}

function SurveyDetailTextBox(data){
    return (
        <div className="surveyDetailTextBox">
            <h1 className="surveyDetailTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.text ? <p className="surveyDetailText" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
            {data.children}
        </div>
    );
}

function SurveyDetailChart(data){
    return (
        <button type="button" onClick={()=>{data.func(data.id,typeChk(data.type,true))}} className={`surveyDetailChartItem ${data.selVal === data.id ? "active" : ""}`}>
            <div className={`surveyDetailChart ${typeChk(data.type)}`}>
                <span style={{transform: `rotateZ(${typeChk(data.type) === "gColor" ? 90 : typeChk(data.type) === "oColor" ? 135 : typeChk(data.type) === "rColor" ? 180 : 0}deg)`}}/>
            </div>
            <div className="surveyDetailChart_info">
                <DataCaption color={typeChk(data.type)} type={data.type}/>
                <h2 className={`surveyDetailChart_infoVal ${typeChk(data.type)}`} dangerouslySetInnerHTML={{__html:`${data.val||"-"}${data.unit ? ` <span>${data.unit}</span>` : ""}`}}/>
                <p className="surveyDetailChart_infoName" dangerouslySetInnerHTML={{__html:data.name}}/>
            </div>
        </button>
    );
}

function TextBtnItemMFull(data){
    return (
        <button type="button" className={`textBtnItemMFull ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}></button>
    );
}

function SurveyTypeBtn(data){
    return (
        <button type="button" className={`surveyTypeBtn ${data.val === data.id ? "active" : ""} ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false}>
            <h1 className="surveyTypeBtn_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            {data.text ? <p className="surveyTypeBtn_text" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
        </button>
    );
}

function SurveyListTr(data){
    return (
        <TableTr addClass={``}>
        <TableTd
            addClass="mContents"
            style={{minWidth:"0",width:"100%",margin:"0 0 20px"}}
        >
            <DataCaption
                color={data.data?.status === 0 ? "oColor" : data.data?.status === 1 ? "gColor" : "rColor"}
                type={data.data?.status === 0 ? "상담 대기" : data.data?.status === 1 ? "상담 완료" : "상담 실패"}
                addClass={"upSize"}
            />
        </TableTd>
        <TableTd
            addClass=""
            style={{minWidth:"12.2%",width:"12.2%"}}
        >
            <TableTextValItem
                mName={true}
                name={"예약일시"}
                val={`${data.data?.date||"-"} <br/>${data.data?.time||"-"}`}
            />
        </TableTd>
        <TableTd
            addClass=""
            style={{minWidth:"6.6%",width:"6.6%"}}
        >
            <TableTextValItem
                mName={true}
                name={"성별"}
                val={data.data?.gender === "f" ? "여성" : "남성"}
            />
        </TableTd>
        <TableTd
            addClass=""
            style={{minWidth:"8.1%",width:"8.1%"}}
        >
            <TableTextValItem
                mName={true}
                name={"출생년도"}
                val={data.data?.birth||"-"}
            />
        </TableTd>
        <TableTd
            addClass=""
            style={{minWidth:"6.6%",width:"6.6%"}}
        >
            <TableTextValItem
                mName={true}
                name={"키"}
                val={`${data.data?.height||"-"}cm`}
            />
        </TableTd>
        <TableTd
            addClass=""
            style={{minWidth:"6.6%",width:"6.6%"}}
        >
            <TableTextValItem
                mName={true}
                name={"몸무게"}
                val={`${data.data?.weight||"-"}kg`}
            />
        </TableTd>
        <TableTd
            addClass=""
            style={{minWidth:"11.5%",width:"11.5%"}}
        >
            <TableTextValItem
                mName={true}
                name={"콩팥상태"}
                val={data.data?.kidney_status||"-"}
            />
        </TableTd>
        <TableTd
            addClass=""
            style={{minWidth:"11.5%",width:"11.5%"}}
        >
            <TableTextValItem
                mName={true}
                name={"크레아티닌(Cr) 수치"}
                val={data.data?.cr_value}
            />
        </TableTd>
        <TableTd
            addClass=""
            style={{minWidth:"11.5%",width:"11.5%"}}
        >
            <TableTextValItem
                mName={true}
                name={"사구체 여과율"}
                val={`${data.data?.gfr_value||"-"}%`}
            />
        </TableTd>
        <TableTd
            addClass="pcContents"
            style={{minWidth:"12.7%",width:"12.7%"}}
        >
            <DataCaption
                color={data.data?.status === 0 ? "oColor" : data.data?.status === 1 ? "gColor" : "rColor"}
                type={data.data?.status === 0 ? "상담 대기" : data.data?.status === 1 ? "상담 완료" : "상담 실패"}
                addClass={"upSize"}
            />
        </TableTd>
        <TableTd
            addClass=""
            style={{minWidth:"12.7%",width:"12.7%"}}
        >
            {data.data?.status === 0 || data.data?.status === 1 ? 
                <TextBtnItemMFull
                    func={()=>{data.func()}}
                    addClass={data.data?.status === 0 ? "rColor" : data.data?.status === 1 ? "oColor" : ""}
                    contents={data.data?.status === 0 ? "상담 취소" : data.data?.status === 1 ? "상담결과 보기" : null}
                />
            :""}
        </TableTd>
    </TableTr>
    );
}

function SurveySelect(data){
    const [optionOpen,setOptionOpen] = useState(false);
    const box = useRef(null);
    const optionBox = useRef(null);
    const inputItem = useRef(null);

    const closeClick = useCallback(
        e => {
            let inside = box.current ? box.current.contains(e.target) : true;
            if (!inside){
                setOptionOpen(false);window.removeEventListener("click",closeClick);
            }
        }, [optionOpen, box]
    );

    const onChangeEvn = (item) => {
        data.optionFunc(item);
    };

    const onClickEvn = () =>{
        if(data.value){
            setOptionOpen(true);
            data.shFunc();
            setTimeout(() => {
                window.addEventListener("click",closeClick);
            }, 10);
        }else{
            window.errMsg("검색어를 입력해주세요.");
        }
    }

    function valSetting(e){
        let value = e;

        if(value){
            if(data.regexp == "number"){
                value = value.replace(/[^0-9]/gi,"");
            }else if(data.regexp){
                value = value.replace(data.regexp,"");
            }

            value = data.max ? value.slice(0, data.max) : value
        }
        return value ? value : "";
    }

    useEffect(()=>{
        inputItem.current.value = data.value ? valSetting(data.value) : "";
    },[data.pageSetting])

    useEffect(()=>{
        optionBox.current.scrollTop = 0;
    },[optionOpen]);
    
    return (
        <div className={`surveySelectItemBox inputItemBox ${valSetting() !== "" ? "active" : ""} ${data.boxAddClass && data.boxAddClass !== "" ? data.boxAddClass : ""}`} ref={box}>
            {data.inputName && data.inputName !== "" ? <h2 className="inputName"  dangerouslySetInnerHTML={{__html:data.inputName}}/> : ""}
            <div className={`inputBox surveySelectInputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""} ${optionOpen ? "active" : ""}`}>                
                <input ref={inputItem} type={"text"} inputMode={"text"} pattern={""} value={valSetting(data.value)} max={data.max && data.max !== "" ? data.max : "null"} className="inputItem" onInput={(e) => {data.func(valSetting(e.target.value))}} onPaste={(e) => {data.func(valSetting(e.target.value))}} onChange={(e) => {data.func(valSetting(e.target.value))}} onKeyUp={(e) => {data.func(valSetting(e.target.value))}} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}{data.caption && data.caption !== "" ? <p className="inputCaption" dangerouslySetInnerHTML={{__html:data.caption}}/> : ""}
                <button type="button" className={`btn_inputSh`} onClick={onClickEvn}><img src="/assets/images/basic/sh_icon.svg"/></button>
            </div>
            <div className={`surveySelect_optionBox ${optionOpen ? "active" : ""}`} ref={optionBox} onScroll={()=>data.boxScrollFunc(optionBox?.current)}>
                {data.options && data.options?.map((item,i)=>(
                    <button type="button" key={i} className={`surveySelect_option ${data.optionValue && data.optionValue?.filter((el) => el.id == item.id)[0] ? "active" : ""}`} onClick={()=>{onChangeEvn(item)}} dangerouslySetInnerHTML={{__html:item.food_name}}></button>
                ))}
            </div>
        </div>
    );
}

function SurveySelectSelItem(data){
    return (
        <div className="surveySelectSelItemArea">
            {data.data?.map((item,i)=>(
                <div className="surveySelectSelItem">
                    <h1 className="surveySelectSelItemName" dangerouslySetInnerHTML={{__html:item.food_name}}/>
                    <div className="surveySelectSelItem_controllBox">
                        <SurveyCountInput
                            val={item.gram}
                            func={(val)=>{data.countFunc(item.id,val)}}
                        />
                        <button type="button" className={`surveySelectSelItemDel`} onClick={() => data.delFunc(item.id)}>
                            <img src="/assets/images/icon/del.svg"/>
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
}

function SurveyCountInput(data){
    const [count,setCount] = useState(data.val ? data.val : 100);

    function setCountUp(){
        data.func(countVal(data.val) + 100);
    }

    function setCountDown(){
        if(countVal(data.val) > 100){
            data.func(countVal(data.val) - 100);
        }else{
            if(data.delType){
                data.delFunc();
            }
        }
    }

    function countVal(e){
        let value = e;

        return value ? value : "";
    }

    return (
        <div className={`countBox ${data.addClass||""}`}>
            <button type="button" onClick={() => setCountDown()} className="btn_m"><img src={`/assets/images/basic/ms.svg`}/></button>
            <input type="text" value={`${countVal(data.val)}g`} readOnly className="countInput"/>
            <button type="button" onClick={() => setCountUp()} className="btn_p"><img src={`/assets/images/basic/ps.svg`}/></button>
        </div>
    );
}

function SurveyDetailConsultingItem(data){
    return (
        <div className={`surveyDetailConsultingItem ${data.type}`}>
            <div className="surveyDetailConsultingItemBox">
                {data.type === "answer" ?
                <div className="surveyDetailConsulting_header">
                    <img src="/assets/images/icon/answer.svg"/>
                    <h2 className="surveyDetailConsulting_header_name">맛있저염 영양상담사</h2>
                </div>
                :""}
                <div className="surveyDetailConsulting_contents">
                    {data.type === "question" ? 
                        <img src="/assets/images/icon/question.svg"/>
                    :""}
                    <p className="surveyDetailConsulting_text" dangerouslySetInnerHTML={{__html:data.text}}/>
                </div>
            </div>
        </div>
    );
}

function SurveyDetailConsulting(data){
    return (
        <div className="surveyDetailConsulting">
            {data.data?.map((item,i)=>(
                <Fragment key={i}>
                    {item.question ? <SurveyDetailConsultingItem type="question" text={item.question}/> : ""}
                    {item.answer ? <SurveyDetailConsultingItem type="answer" text={item.answer}/> : ""}
                </Fragment>
            ))}
        </div>
    );
}

export {SurveyInfo, SurveyDetailSection, SurveyDetailTitleBox, SurveyDetailUserInfo, SurveyDetailNutrition, SurveyDetailTextBox, SurveyDetailChart,TextBtnItemMFull, SurveyTypeBtn, SurveyListTr, SurveySelect, SurveyCountInput, SurveySelectSelItem, SurveyDetailConsulting};