import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, InputContents } from "component/app/items";
import { Header } from "component/elements/header";
import { TermsContents, TermsContentsBox, TermsTitle } from "component/terms";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputBoxCol2, InputBoxOnly, InputItemBox, InputTap } from "component/basic/formItems";
import { SurveyInfo } from "component/survey/items";

const HealthSurvey = (props) => {
    const navigate = useNavigate();
    const type = sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : "my";

    const [page,setPage] =  useState(0);
    const [btnChk,setBtnChk] =  useState(true);

    const [gender,setGender] =  useState(null);
    const [birthday,setBirthday] =  useState(null);
    const [userHeight,setUserHeight] =  useState(null);
    const [userWeight,setUserWeight] =  useState(null);

    const editApi = useGet({
        url:`/health/data/editor`,
        loginType:"login"
    });
    const editApiData = editApi?.data?.health_data_contents;

    const userApi = useGet({
        url: sessionStorage.getItem("familyId") ? `/family/profile/detail?user_family_id=${sessionStorage.getItem("familyId")}` : `/profile?is_simple=1`,
        loginType:"login"
    });
    const userApiData = sessionStorage.getItem("familyId") ? userApi?.data?.user_family : userApi?.data?.user;
    
    const [disease,setDisease] =  useState(null);
    
    const [cr_value,setCr_value] =  useState(null);
    const [gfr_value,setGfr_value] =  useState(null);

    const [diabetes_type,setDiabetes_type] =  useState(null);
    const [fasting_blood_sugar,setFasting_blood_sugar] =  useState(null);
    const [after_meal_blood_sugar,setAfter_meal_blood_sugar] =  useState(null);
    const [glycated_hemoglobin,setGlycated_hemoglobin] =  useState(null);

    const [before_6month_change_weight,setBefore_6month_change_weight] =  useState(null);
    const [edema,setEdema] =  useState(null);
    const [loss_of_appetite,setLoss_of_appetite] =  useState(null);
    const [cancer_type,setCancer_type] =  useState(null);
    const [need_manage_disease,setNeed_manage_disease] =  useState([]);
    const [albumin_value,setAlbumin_value] =  useState(null);
    const [hb_value,setHb_value] =  useState(null);

    function dataReSet(){
        //page 2
        setGender(null);
        setBirthday(null);
        setUserHeight(null);
        setUserWeight(null);

        //page 3
        setDisease(null);

        //page 4
        setCr_value(null);
        setGfr_value(null);

        setDiabetes_type(null);
        setFasting_blood_sugar(null);
        setAfter_meal_blood_sugar(null);
        setGlycated_hemoglobin(null);

        setBefore_6month_change_weight(null);
        setEdema(null);
        setLoss_of_appetite(null);
        setCancer_type(null);
        setAlbumin_value(null);
        setHb_value(null);
        setNeed_manage_disease([]);
    }

    function pageBack(){
        if(page === 1){
            dataReSet();
        }
        if(page === 3){
            page_2_btnChk();
        }
        if(page === 4){
            setDisease(null);

            setCr_value(null);
            setGfr_value(null);

            setDiabetes_type(null);
            setFasting_blood_sugar(null);
            setAfter_meal_blood_sugar(null);
            setGlycated_hemoglobin(null);

            setBefore_6month_change_weight(null);
            setEdema(null);
            setLoss_of_appetite(null);
            setCancer_type(null);
            setAlbumin_value(null);
            setHb_value(null);
            setNeed_manage_disease([]);
        }
        setPage(page - 1);
    }

    function pageNext(){
        if(page === 1){
            page_2_btnChk();
        }else if(page === 2){
            page_3_btnChk();
        }else{
            setBtnChk(true)
        }
        
        setPage(page + 1);
    }

    function surveyStart(){
        if(userApiData){
            setGender(userApiData?.gender ? String(userApiData?.gender) : null);
            setBirthday(userApiData?.birth ? String(userApiData?.birth) : null);
            setUserHeight(userApiData?.height ? String(userApiData?.height) : null);
            setUserWeight(userApiData?.weight ? String(userApiData?.weight) : null);
        }

        setPage(1);
        setBtnChk(false);
    }

    function page_2_btnChk(){
        if(
            gender && gender !== ""
            && birthday && birthday !== ""
            && userHeight && userHeight !== ""
            && userWeight && userWeight !== ""
        ){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }

    function page_3_btnChk(){
        if(
            disease && disease !== ""
        ){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }

    function surveySubmit(){
        let formData = new FormData();
        
        if(type !== "my"){
            formData.append("user_family_id", type)
        }

        formData.append("gender", gender);
        formData.append("birth", birthday);
        formData.append("height", userHeight);
        formData.append("weight", userWeight);

        formData.append("disease", disease);

        if(disease === "신장"){
            formData.append("cr_value", cr_value);
            formData.append("gfr_value", gfr_value);
        }

        if(disease === "당뇨"){
            formData.append("diabetes_type", diabetes_type);
            formData.append("fasting_blood_sugar", fasting_blood_sugar);
            formData.append("after_meal_blood_sugar", after_meal_blood_sugar);
            formData.append("glycated_hemoglobin", glycated_hemoglobin);
        }

        if(disease === "암"){
            formData.append("before_6month_change_weight", before_6month_change_weight);
            formData.append("edema", edema);
            formData.append("loss_of_appetite", loss_of_appetite);
            formData.append("cancer_type", cancer_type);
            
            formData.append("albumin_value", albumin_value);
            formData.append("hb_value", hb_value);

            if(need_manage_disease?.length > 0){
                formData.append("need_manage_disease", need_manage_disease?.join());
            }
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType:"login",
            url: "/health/data/register",
            success: (data) => {
                navigate(`/healthSurvey/detail`)
            },
            err: (data) => {
                window.errPopupOpen(data);
            }
        })
    }

    useEffect(() => {
        if(page === 2){
            page_2_btnChk();
        }
    }, [gender,birthday,userHeight,userWeight]);

    useEffect(() => {
        if(page === 3){
            page_3_btnChk();
        }
    }, [disease]);

    useEffect(() => {
        if(page === 4 && disease === "신장"){
            if(
                cr_value && cr_value !== ""
                && gfr_value && gfr_value !== ""
            ){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [cr_value,gfr_value]);

    useEffect(() => {
        if(page === 4 && disease === "당뇨"){
            if(
                diabetes_type && diabetes_type !== ""
                && fasting_blood_sugar && fasting_blood_sugar !== ""
                && after_meal_blood_sugar && after_meal_blood_sugar !== ""
                && glycated_hemoglobin && glycated_hemoglobin !== ""
            ){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [diabetes_type,fasting_blood_sugar,after_meal_blood_sugar,glycated_hemoglobin]);

    useEffect(() => {
        if(page === 4 && disease === "암"){
            if(
                (before_6month_change_weight||before_6month_change_weight === 0) && before_6month_change_weight !== ""
                && (edema||edema === 0) && edema !== ""
                && (loss_of_appetite||loss_of_appetite === 0) && loss_of_appetite !== ""
                && cancer_type && cancer_type !== ""
                && albumin_value && albumin_value !== ""
                && hb_value && hb_value !== ""
            ){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [before_6month_change_weight,edema,loss_of_appetite,cancer_type,albumin_value,hb_value,need_manage_disease]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={false}
                mBtn_r2={true}
            >
                <Header
                    subLinkType="care"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                {page === 0 ? 
                    <>
                    <TermsContentsBox addClass="pageType">
                        <TermsTitle title={"건강 데이터 등록 안내"}/>
                        <TermsContents contents={editApiData}/>
                    </TermsContentsBox>
                    <BtnBox
                        boxType="fixed"
                        addClass="pcFixedBtnBox row2"
                    >
                        <BtnItem
                            addClass=""
                            contents={"건강 데이터 등록"}
                            func={() => {surveyStart()}}
                        />
                    </BtnBox>
                    </>
                :""}
                {page !== 0 ? 
                    <>
                    <InputContents
                        title={
                            page === 1 ? "건강 데이터 등록 안내"
                            : page === 2 ? "기본정보 입력"
                            : page === 3 ? "질환정보 선택"
                            : page === 4 ? "상세 수치 입력"
                            : ""
                        }
                        subTitle={
                            page === 1 ? "고객님의 건강 진단 및 데이터 등록을 위해<br/>서베이를 진행할게요, 서베이는 약 3분정도 소요됩니다."
                            : page === 2 ? `${userApiData?.name} 님의 정확한 정보를 입력해 주세요.`
                            : page === 3 ? `${userApiData?.name} 님이 현재 겪고계시는 질환을 선택해주세요.`
                            : page === 4 ? 
                                disease === "신장" ? "크레아티닌(Cr, Creatinine) 수치를 입력해 주세요."
                                : disease === "당뇨" ? "진단받으신 당뇨 유형과 혈당을 입력해 주세요."
                                : disease === "암" ? "최근 건강상태와 진단받으신 질환 정보를 입력해 주세요."
                                : ""
                            :""
                        }
                    >
                        {page === 1 ?
                            <img src="/assets/images/img/survey_img.png" className="survey_img"/>
                        :""}
                        {page === 2 ? 
                            <>
                                <InputBoxOnly addClass="" inputName="성별">
                                    <InputTap
                                        data={[
                                            {
                                                id:"m",
                                                name:"남성"
                                            },
                                            {
                                                id:"f",
                                                name:"여성"
                                            }
                                        ]}
                                        val={gender}
                                        func={(value) => {setGender(value)}}
                                    />
                                </InputBoxOnly>
                                <InputItemBox
                                    inputType={null}
                                    addClass=""
                                    inputName="출생년도"
                                    placeholder="출생년도"
                                    value={birthday}
                                    unit={"년"}
                                    birthYY={true}
                                    max={4}
                                    regexp={"number"}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    func={(value)=>{setBirthday(value)}}
                                />
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="키"
                                        placeholder="예 : 165"
                                        value={userHeight}
                                        max={3}
                                        unit={"cm"}
                                        regexp={"number"}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        func={(value)=>{setUserHeight(value)}}
                                    />
                                    <InputItemBox
                                        inputType={null}
                                        addClass=""
                                        inputName="몸무게"
                                        placeholder="예 : 50"
                                        value={userWeight}
                                        max={3}
                                        unit={"kg"}
                                        regexp={"number"}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        func={(value)=>{setUserWeight(value)}}
                                    />
                                </InputBoxCol2>
                            </>
                        :""}
                        {page === 3 ? 
                            <InputBoxOnly addClass="" inputName={null}>
                                <InputTap
                                    addClass="diseaseTap"
                                    data={[
                                        {
                                            id:"신장",
                                            name:"신장 질환",
                                            addClass:"oColor opacityActive"
                                        },
                                        {
                                            id:"당뇨",
                                            name:"당뇨 질환",
                                            addClass:"bColor opacityActive"
                                        },
                                        {
                                            id:"암",
                                            name:"암 질환",
                                            addClass:"gColor opacityActive"
                                        }
                                    ]}
                                    val={disease}
                                    func={(value) => {setDisease(value)}}
                                />
                            </InputBoxOnly>
                        :""}
                        {page === 4 && disease === "신장" ?
                        <>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="크레아티닌(Cr) 수치"
                                placeholder="예 : 2.0"
                                value={cr_value}
                                max={null}
                                unit={"mg/dL"}
                                regexp={/[^0123456789\.]/gi}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setCr_value(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="사구체여과율"
                                placeholder="0"
                                value={gfr_value}
                                max={null}
                                unit={"%"}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setGfr_value(value)}}
                            />
                            <SurveyInfo func={()=>{}} title="크레아티닌(Cr) 수치는 어떻게 확인하나요?" text="크레아티닌은 혈액 검사를 통해 알 수 있어요.<br/>검사 결과지에서 크레아티닌 수치를 확인 후 입력해주세요."/>
                            <SurveyInfo func={()=>{}} title="사구체여과율은 어떤 방식으로 계산되나요?" text="사구체여과율은 고객님의 나이, 성별, 신체정보를 바탕으로 계산하며, CKD-EPI 공식을 사용하고 있습니다."/>
                        </>
                        :""}
                        {page === 4 && disease === "당뇨" ?
                        <>
                            <InputBoxOnly addClass="" inputName={"진단받은 당뇨 유형"}>
                                <InputTap
                                    addClass=""
                                    data={[
                                        {
                                            id:"1형",
                                            name:"1형",
                                            addClass:""
                                        },
                                        {
                                            id:"2형",
                                            name:"2형",
                                            addClass:""
                                        },
                                        {
                                            id:"임신성",
                                            name:"임신성",
                                            addClass:""
                                        },
                                        {
                                            id:"모르겠어요",
                                            name:"모르겠어요",
                                            addClass:""
                                        }
                                    ]}
                                    val={diabetes_type}
                                    func={(value) => {setDiabetes_type(value)}}
                                />
                            </InputBoxOnly>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="공복 혈당"
                                placeholder="예 : 80"
                                value={fasting_blood_sugar}
                                max={null}
                                unit={"mg/dL"}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setFasting_blood_sugar(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="식후 2시간 뒤 혈당"
                                placeholder="예 : 80"
                                value={after_meal_blood_sugar}
                                max={null}
                                unit={"mg/dL"}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setAfter_meal_blood_sugar(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="당화혈색소"
                                placeholder="예 : 5.7"
                                value={glycated_hemoglobin}
                                max={null}
                                unit={"%"}
                                regexp={/[^0123456789\.]/gi}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setGlycated_hemoglobin(value)}}
                            />
                            <SurveyInfo func={()=>{}} title="당화혈색소가 무엇인가요?" text="최근 2-3개월의 평균 혈당치로 조절 상태를 파악할 수 있는 지표입니다."/>
                        </>
                        :""}
                        {page === 4 && disease === "암" ?
                        <>
                            <InputBoxOnly addClass="" inputName={"최근 6개월 사이에 눈에 띄는 체중 변화가 있었나요?"}>
                                <InputTap
                                    addClass=""
                                    data={[
                                        {
                                            id:"1",
                                            name:"네",
                                            addClass:""
                                        },
                                        {
                                            id:"0",
                                            name:"아니오",
                                            addClass:""
                                        }
                                    ]}
                                    val={before_6month_change_weight}
                                    func={(value) => {setBefore_6month_change_weight(value)}}
                                />
                            </InputBoxOnly>
                            <InputBoxOnly addClass="" inputName={"부종 또는 복수의 증상이 있으신가요?"}>
                                <InputTap
                                    addClass=""
                                    data={[
                                        {
                                            id:"1",
                                            name:"네",
                                            addClass:""
                                        },
                                        {
                                            id:"0",
                                            name:"아니오",
                                            addClass:""
                                        }
                                    ]}
                                    val={edema}
                                    func={(value) => {setEdema(value)}}
                                />
                            </InputBoxOnly>
                            <InputBoxOnly addClass="" inputName={"식욕부진이 있으신가요?"}>
                                <InputTap
                                    addClass=""
                                    data={[
                                        {
                                            id:"1",
                                            name:"네",
                                            addClass:""
                                        },
                                        {
                                            id:"0",
                                            name:"아니오",
                                            addClass:""
                                        }
                                    ]}
                                    val={loss_of_appetite}
                                    func={(value) => {setLoss_of_appetite(value)}}
                                />
                            </InputBoxOnly>
                            <InputBoxOnly addClass="" inputName={"진단받은 질환을 선택해 주세요."}>
                                <InputTap
                                    addClass=""
                                    data={[
                                        {
                                            id:"간암",
                                            name:"간암",
                                            addClass:""
                                        },
                                        {
                                            id:"갑상선암",
                                            name:"갑상선암",
                                            addClass:""
                                        },
                                        {
                                            id:"대장암",
                                            name:"대장암",
                                            addClass:""
                                        },
                                        {
                                            id:"유방암",
                                            name:"유방암",
                                            addClass:""
                                        },
                                        {
                                            id:"위암",
                                            name:"위암",
                                            addClass:""
                                        },
                                        {
                                            id:"자궁경부암",
                                            name:"자궁경부암",
                                            addClass:""
                                        },
                                        {
                                            id:"폐암",
                                            name:"폐암",
                                            addClass:""
                                        },
                                        {
                                            id:"기타",
                                            name:"기타",
                                            addClass:""
                                        },
                                    ]}
                                    val={cancer_type}
                                    func={(value) => {setCancer_type(value)}}
                                />
                            </InputBoxOnly>
                            <InputBoxOnly addClass="" inputName={"암 질환 외 관리가 필요한 질환이 있나요? (중복 선택)"}>
                                <InputTap
                                    addClass=""
                                    multi={true}
                                    data={[
                                        {
                                            id:"간 질환",
                                            name:"간 질환",
                                            addClass:""
                                        },
                                        {
                                            id:"고혈압",
                                            name:"고혈압",
                                            addClass:""
                                        },
                                        {
                                            id:"고콜레스테롤혈증",
                                            name:"고콜레스테롤혈증",
                                            addClass:""
                                        },
                                        {
                                            id:"고중성지방혈증",
                                            name:"고중성지방혈증",
                                            addClass:""
                                        },
                                        {
                                            id:"골다공증",
                                            name:"골다공증",
                                            addClass:""
                                        },
                                        {
                                            id:"당뇨병",
                                            name:"당뇨병",
                                            addClass:""
                                        },
                                        {
                                            id:"기타",
                                            name:"기타",
                                            addClass:""
                                        },
                                        {
                                            id:"없음",
                                            name:"없음",
                                            addClass:""
                                        }
                                    ]}
                                    val={need_manage_disease}
                                    func={(value) => {
                                        let oldData = [...need_manage_disease];
                                        if(oldData?.filter((el) => el === value)[0]){
                                            oldData = oldData?.filter((el) => el !== value);
                                        }else{
                                            oldData.push(value)
                                        }

                                        setNeed_manage_disease(oldData)
                                    }}
                                />
                            </InputBoxOnly>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="알부민 수치"
                                placeholder="예 : 3.5"
                                value={albumin_value}
                                max={null}
                                unit={"g/dL"}
                                regexp={/[^0123456789\.]/gi}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setAlbumin_value(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="헤모글로빈 (Hb) 수치"
                                placeholder="예 : 13.5"
                                value={hb_value}
                                max={null}
                                unit={"g/dL"}
                                regexp={/[^0123456789\.]/gi}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setHb_value(value)}}
                            />
                            <SurveyInfo func={()=>{}} title="알부민 (Albumin) 수치가 무엇인가요?" text="최근 한 달 이내의 영양 상태를 반영하는 중요한 지표입니다."/>
                            <SurveyInfo func={()=>{}} title="헤모글로빈 (Hemoglobin) 수치가 무엇인가요?" text="빈혈의 지표로 영양 상태 반영 지표입니다."/>
                        </>
                        :""}
                        <BtnBox
                            boxType="fixed"
                            addClass="pcBasicBtnBox row2"
                        >
                            <BtnItem
                                addClass=""
                                contents={page === 1 ? "서베이 시작" : page === 4 ? "결과 확인하기" : "다음"}
                                disabled={btnChk}
                                func={() => {
                                    if(page === 4){
                                        surveySubmit()
                                    }else{
                                        pageNext()
                                    }
                                }}
                            />
                            <BtnItem
                                addClass="line"
                                contents={page === 1 ? "취소" : "뒤로"}
                                disabled={false}
                                func={() => {pageBack()}}
                            />
                        </BtnBox>
                    </InputContents>
                    </>
                :""}
            </ContentsSection>
        </>
    );
};

export default HealthSurvey;