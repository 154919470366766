import { DataCaption } from "component/basic";
import { typeChk } from "js/function";
import { useNavigate } from "react-router-dom";
import { useSwiper } from "swiper/react";

function MainBannerControll(data){
    const swiper = useSwiper();
    return (
        <div className="mainBannerControll">
            <div className="mainBannerControllBox">
                <button className="swiper-button-prev" onClick={() => swiper.slidePrev()}/>
                <button className="swiper-button-next" onClick={() => swiper.slideNext()}/>
            </div>
        </div>
    );
};

function MainDataSection(data){
    return (
        <div className={`mainDataSection ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function MainDataArea(data){
    return (
        <div className={`mainDataArea ${data.addClass||""}`}>
            <div className="mainDataBox">
                {data.children}
            </div>
        </div>
    );
}

function MainDataTitleBox(data){
    return (
        <div className={`mainDataTitleBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function MainDataTitle(data){
    return (
        <h1 className="mainDataTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
    );
}

function MainDataSubTitle(data){
    return (
        <h1 className={`mainDataSubTitle ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.title}}/>
    );
}

function MainDataSubText(data){
    return ( 
        <h1 className={`mainDataSubText ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.text}}/>
    );
}

function MainDataInfoItem(data){
    return (
        <div className="mainDataInfoItem">
            <p className="mainDataInfoItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <h2 className="mainDataInfoItem_val" dangerouslySetInnerHTML={{__html:`${data.val} <span>${data.unit}</span>`}}/>
        </div>
    );
}

function MainDataHealthInfoItem(data){
    return (
        <div className="mainDataHealthInfoItem">
            <img src={`/assets/images/icon/${data.icon}.svg`}/>
            <div className="mainDataHealthInfoItem_info"> 
                <div className="mainDataHealthInfoItem_infoNameBox">
                    <p className="mainDataHealthInfoItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
                    {data.type ? <DataCaption color={typeChk(data.type)} type={data.type}/> : ""}
                </div>
                <h2 className={`mainDataHealthInfoItem_val ${typeChk(data.type)}`} dangerouslySetInnerHTML={{__html:`${data.val}<span>${data.unit ? ` ${data.unit}` : ""}</span>`}}/>
            </div>
        </div>
    );
}

function MainSubBanner(data){
    return (
        <div className="mainSubBanner">
            <a href={data.url} target="_blank" style={{"backgroundImage":`url('${data.image_url}')`}} className="backgroundImg mainSubBannerImg"/>
        </div>
    );
}

function MainNotMember(data){
    const navigate = useNavigate();

    return (
        <div className="mainNotMember">
            <div className="mainNotMemberBox">
                <img src="/assets/images/icon/main_care_img.svg"/>
                <h1 className="mainNotMember_title">케어센터를 이용하시려면<br/>지금 바로 <span className="oTextColor">맛있저염 회원 가입하기</span></h1>
                <p className="mainNotMember_text">맛있저염 몰을 가입하시면,<br/>데이터 기반으로 나의 건강 현황과 하루에 섭취 필요한 영양구성을 케어센터를 통해 확인해보실 수 있어요.</p>
                <button type="button" className="mainNotMember_btn" onClick={()=>{
                    let nowUrl = new URL(window.location.href);
                    sessionStorage.setItem("setUrl",nowUrl.pathname);
                    navigate("/welCome")
                }}>바로 가입하러 가기<img src="/assets/images/basic/move_min_w.svg"/></button>
            </div>
        </div>
    );
}

export {MainBannerControll,MainDataSection,MainDataArea,MainDataTitleBox,MainDataTitle,MainDataSubTitle,MainDataSubText,MainDataInfoItem,MainDataHealthInfoItem,MainSubBanner,MainNotMember}