import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CustomSelect, InputBoxCol2, InputBoxOnly, InputItemBox, InputTap } from "component/basic/formItems";
import { LoadingBox, Popup } from "component/basic/popup";
import { Header, } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { ContentsSection, InputContents, InputContentsSubTitle, InputContentsSubTitleBox } from "component/app/items";
import { Footer } from "component/elements/footer";

const Join = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const joinType = param.type;
    const marketing = param.marketing;

    const nowDate = new Date();

    const [page,setPage] =  useState(0);
    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    
    const [btnChk,setBtnChk] =  useState(true);

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("3:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);

    const [responseSeq,setResponseSeq] =  useState("");
    
    const [id,setId] =  useState("");
    const [pw,setPw] =  useState("");
    const [pwCom,setPwCom] =  useState("");

    const [idErr,setIdErr] =  useState(null);
    const [pwErr,setPwErr] =  useState(null);
    const [pwComErr,setPwComErr] =  useState(null);
    const [userHeight,setUserHeight] =  useState(null);
    const [userWeight,setUserWeight] =  useState(null);

    const [name,setName] =  useState("");
    const [birthday,setBirthday] =  useState("");
    const [birthdayBack,setBirthdayBack] =  useState("");
    const [phone,setPhone] =  useState("");
    const [phoneType,setPhoneType] =  useState("");

    const [submitChk,setSubmitChk] =  useState(true);

    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("인증시간이 초과되었습니다.");
                    setCetifiedComChk(true);
                    setCetified("");

                    setBtnChk(false);
                }
            }, 1000);
        }
    }

    function joinBack(){
        if(page === 1){
            setPage(0)
        }else{
            navigate(-1)
        }
    }

    function joinNextFunc(){
        setBtnChk(true);
        setPwErr(null)
        setPwComErr(null)
        setIdErr(null)
                    
        setPage(page + 1);
    }

    function joinComPopup(){
        setPopupData({
            addClass:null,
            title:"회원가입 완료",
            text:"회원가입이 완료되었습니다.<br/>지금 바로 맛있저염을 이용해 보세요.",
            closeType:false,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{
                localStorage.setItem("token", sessionStorage.getItem("token"));
                localStorage.setItem("refresh_token", sessionStorage.getItem("refresh_token"));
                                                
                window.logIn();

                setTimeout(() => {                    
                    if(sessionStorage.getItem("setUrl")){
                        let reUrl = sessionStorage.getItem("setUrl");
                        sessionStorage.removeItem("setUrl");
                        navigate(reUrl);
                    }else{
                        navigate(`/`);
                    }
                }, 100);
            },
            btn0:"확인",
            btn0Type:"",
            btn1:"",
            btn1Type:"",
            btnFunc1:()=>{}
        });

        setTimeout(() => {
            localStorage.setItem("token", sessionStorage.getItem("token"));
            localStorage.setItem("refresh_token", sessionStorage.getItem("refresh_token"));
                                            
            window.logIn();

            setTimeout(() => {                    
                if(sessionStorage.getItem("setUrl")){
                    let reUrl = sessionStorage.getItem("setUrl");
                    sessionStorage.removeItem("setUrl");
                    navigate(reUrl);
                }else{
                    navigate(`/`);
                }
            }, 100);
        }, 5000);
    }

    function cetifiedStert(){
        if(submitChk){
            setSubmitChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("name", name);
            formData.append("birth", `${birthday}${birthdayBack}`);
            formData.append("phone", phone?.replace(/\-/g,""));
            formData.append("mobile_carrier", phoneType.val);
            formData.append("is_join", 1);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType: joinType == "sns" ? "sessionLogin" : null,
                url: `/certification`,
                success: (data) => {
                    setSubmitChk(true);

                    timerStart();
                    setCetified("");
                    setCetifiedChk(true);
                    setLoadingBoxOpen(false);

                    setBtnChk(true);

                    setResponseSeq(data?.data?.response_seq);
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        window.errPopupOpen(data);
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function joinNext(){
        if(page == 0){
            if(submitChk){
                if(cetifiedChk){
                    setSubmitChk(false);
                    setLoadingBoxOpen(true);

                    let formData = new FormData();
                    formData.append("name", name);
                    formData.append("birth", `${birthday}${birthdayBack}`);
                    formData.append("phone", phone?.replace(/\-/g,""));
                    formData.append("mobile_carrier", phoneType.val);

                    formData.append("response_seq", responseSeq);
                    formData.append("certification_code", cetified);
                    formData.append("is_join", 1);

                    fatchSet.FatchApi({
                        type:"POST",
                        formDataItem: formData,
                        loginType: joinType == "sns" ? "sessionLogin" : null,
                        url: `/certification/check`,
                        success: (data) => {
                            setSubmitChk(true);

                            setBtnChk(false);
                            setCetifiedComChk(true);

                            setTimerCount("3:00");
                            setCertifyErr("");

                            clearInterval(sval.current);
                            sval.current = null;

                            setLoadingBoxOpen(false);
                            
                            if(joinType == "normal"){
                                sessionStorage.setItem("token",data.data.token.access_token);
                                sessionStorage.setItem("refresh_token",data.data.token.refresh_token);

                                joinNextFunc();
                            }else if(data.data.is_user === 1){
                                joinComPopup();
                            }else{
                                joinNextFunc();
                            }
                        },
                        err: (data) => {
                            setSubmitChk(true);
                            if(data.alert){
                                setCertifyErr(data.alert);
                            }
                            setLoadingBoxOpen(false);
                        }
                    })
                }else{
                    cetifiedStert();setCetifiedComChk(false);setCetified("")
                }
            }
        }else if(page == 1){
            if(submitChk){
                setSubmitChk(false);
                setLoadingBoxOpen(true);

                let formData = new FormData();
                formData.append("login_id", id);
                formData.append("password", pw);
                formData.append("agree_marketing", marketing);

                if(userHeight){
                    formData.append("height", userHeight);
                }
                if(userWeight){
                    formData.append("weight", userWeight);
                }

                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    loginType: "sessionLogin",
                    url: `/join`,
                    success: (data) => {
                        setLoadingBoxOpen(false);
                        setSubmitChk(true);

                        joinComPopup();
                    },
                    err: (data) => {
                        setSubmitChk(true);
                        if(data.data || data.alert){
                            window.errPopupOpen(data);
                        }
                        setLoadingBoxOpen(false);
                    }
                })
            }
        }
    }

    useEffect(() => {
        let idType = /(^(?=.*[a-zA-Z])(?=.*[0-9]).{4,20}$)|(^(?=.*[a-zA-Z]).{4,20}$)/;
        let pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/;

        if(!idType.test(id) && id !== ""){
            setIdErr("영문 혹은 영문, 숫자 조합 4-20자로 설정해 주세요.")
        }else{
            setIdErr(null)
        }

        if(pw !== pwCom && pw !== "" && pwCom !== ""){
            setPwComErr("비밀번호가 일치하지 않습니다.")
        }else{
            setPwComErr(null)
        }

        if(!pwType.test(pw) && pw !== ""){
            setPwErr("영문, 숫자, 특수문자 조합 8-20자로 설정해 주세요.")
        }else{
            setPwErr(null)
        }

        if(page == 1){
            if(
                idType.test(id) && id !== ""
                && pwType.test(pw) && pw !== ""
                && pw == pwCom && pwType.test(pw)
            ){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [id,pw,pwCom]);

    useEffect(() => {
        let ragChk = /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[1-4]/g;
        let phoneChk = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

        if(page == 0){
            if(name.length > 0 
            && ragChk.test(`${birthday}${birthdayBack}`)
            && phoneChk.test(phone?.replace(/\-/g,""))
            && phoneType.val
            && (cetifiedChk ? cetified.length == 6 : true)){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [name,birthday,birthdayBack,phone,phoneType,cetified,cetifiedChk]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={false}
                mBtn_r2={true}
                pcFooter={true}
            >
                <Header
                    subLinkType="care"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <InputContents
                    title={page === 0 ? "본인인증" : "회원정보 입력"}
                    subTitle={page === 0 ? "회원가입을 위해선 본인인증이 필요해요." : "회원님의 정보를 입력해 주세요."}
                >
                    {
                        page === 0 ? 
                        <>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="휴대폰 번호"
                                placeholder="‘-’ 없이 입력"
                                value={phone}
                                max={13}
                                phoneSetting={true}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setPhone(value);setCetifiedChk(false);setCetifiedComChk(false);setCetified("")}}
                            />
                            <CustomSelect
                                addClass="notName"
                                inputName="통신사"
                                placeholder="선택"
                                value={phoneType?.val}
                                func={(name,val) => {
                                    let dataSet = {
                                        val:val,
                                        text:name
                                    }
                                    setPhoneType(dataSet);
                                }}
                                disabled={false}
                                valKey="id"
                                nameKey="name"
                                options={[
                                    {id:1,name:"SKT"},
                                    {id:2,name:"KT"},
                                    {id:3,name:"LGU+"},
                                    {id:5,name:"알뜰폰(SKT)"},
                                    {id:6,name:"알뜰폰(KT)"},
                                    {id:7,name:"알뜰폰(LGU+)"}
                                ]}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="이름"
                                placeholder="이름"
                                value={name}
                                max={null}
                                regexp={null}
                                func={(value)=>{setName(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="주민등록번호 앞 7자리"
                                placeholder="주민등록번호 앞 7자리"
                                value={birthday}
                                value2={birthdayBack}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                registrationType={true}
                                func={(value)=>{setBirthday(value)}}
                                func2={(value)=>{setBirthdayBack(value)}}
                            />
                            {cetifiedChk ? 
                                <InputItemBox
                                    inputType={null}
                                    addClass="cetifiedInput"
                                    inputName="인증 번호"
                                    placeholder="인증 번호"
                                    value={cetified}
                                    max={6}
                                    readOnly={cetifiedComChk}
                                    cetified={timerCount}
                                    regexp={"number"}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    func={(value)=>{setCetified(value)}}
                                    errMsg={certifyErr}
                                    btnName="재발송"
                                    clickEv={()=>{cetifiedStert();setCetifiedComChk(false);setCetified("")}}
                                />
                            :""}
                        </>
                    : page === 1 ? 
                        <>
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="아이디"
                            placeholder="영문 혹은 영문, 숫자 조합 4-20자"
                            value={id}
                            max={20}
                            regexp={/[^a-zA-Z0-9]/gi}
                            errMsg={idErr}
                            func={(value)=>{setId(value)}}
                        />
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="비밀번호"
                            placeholder="영문, 숫자, 특수문자 조합 8~20자"
                            value={pw}
                            pwChType={true}
                            max={20}
                            regexp={null}
                            errMsg={pwErr}
                            func={(value)=>{setPw(value)}}
                        />
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="비밀번호 확인"
                            placeholder="영문, 숫자, 특수문자 조합 8~20자"
                            value={pwCom}
                            pwChType={true}
                            max={20}
                            regexp={null}
                            errMsg={pwComErr}
                            func={(value)=>{setPwCom(value)}}
                        />     
                        <InputContentsSubTitleBox>
                            <InputContentsSubTitle title="선택정보 입력"/>
                        </InputContentsSubTitleBox>
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="이름"
                            placeholder="이름"
                            value={name}
                            readOnly={true}
                            max={null}
                            regexp={null}
                            func={(value)=>{}}
                        />
                        <InputBoxOnly addClass="" inputName="성별">
                            <InputTap
                                data={[
                                    {
                                        id:"m",
                                        name:"남성"
                                    },
                                    {
                                        id:"f",
                                        name:"여성"
                                    }
                                ]}
                                val={birthdayBack == 1 || birthdayBack == 3 ? "m" : "f"}
                                disabled={true}
                                func={(value) => {}}
                            />
                        </InputBoxOnly>
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="출생년도"
                            placeholder="출생년도"
                            value={birthday?.slice(0, 2) > String(nowDate?.getFullYear())?.slice(2, 4) ? `19${birthday?.slice(0, 2)}` : `20${birthday?.slice(0, 2)}`}
                            readOnly={true}
                            unit={"년"}
                            max={null}
                            regexp={null}
                            func={(value)=>{}}
                        />
                        <InputBoxCol2>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="키"
                                placeholder="예 : 165"
                                value={userHeight}
                                max={3}
                                unit={"cm"}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setUserHeight(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="몸무게"
                                placeholder="예 : 50"
                                value={userWeight}
                                max={3}
                                unit={"kg"}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setUserWeight(value)}}
                            />
                        </InputBoxCol2>
                        </>           
                    : ""}
                    <BtnBox
                        boxType="fixed"
                        addClass="pcBasicBtnBox row2"
                    >
                        <BtnItem
                            addClass=""
                            contents={page === 0 ? cetifiedChk ? "본인인증 확인" : "인증번호 발송" : "회원가입 완료"}
                            disabled={btnChk}
                            func={() => {joinNext()}}
                        />
                        <BtnItem
                            addClass="line"
                            contents={"뒤로"}
                            disabled={false}
                            func={() => {joinBack()}}
                        />
                    </BtnBox>
                </InputContents>
            </ContentsSection>
            <Footer pcOnly={true}/>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </>
    );
};

export default Join;