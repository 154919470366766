import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { CustomPopup, Popup } from "component/basic/popup";
import { FamilyItem, FamilyItemAddBtn } from "component/mypage/family";
import { BtnBox, BtnItem, TextBtnItem, TextBtnItemBox } from "component/basic/btns";
import { PopupInputBox } from "component/basic";
import { InputBoxCol2, InputBoxOnly, InputItemBox, InputTap } from "component/basic/formItems";
import { MypageAreaTitleBox, MypageContents, MypageSection, MypageSideMenu } from "component/mypage/items";

const MyFamily = (props) => {
    const navigate = useNavigate();

    const [familyId,setFamilyId] =  useState(sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : null);

    const [submitChk,setSubmitChk] =  useState(true);
    const [selId,setSelId] =  useState(null);

    const [popupData,setPopupData] =  useState(null);
    const [customPopupFamily,setCustomPopupFamily] =  useState(null);
    const [btnChk,setBtnChk] =  useState(null);

    const [familyApiUpdate,setFamilyApiUpdate] =  useState(0);
    const familyDataApi = useGet({
        url:`/family/profile?update=${familyApiUpdate}`,
        loginType:"login"
    });
    const familyData = familyDataApi?.data?.user_families;

    const familyDetailDataApi = useGet({
        url:`/family/profile/detail?user_family_id=${selId}`,
        loginType:"login"
    });
    const familyDetailData = familyDetailDataApi?.data?.user_family;

    const profileDataApi = useGet({
        url:`/profile`,
        loginType:"login"
    });
    const user = profileDataApi?.data?.user;

    const [familyInputData,setFamilyInputData] =  useState({
        id:null,
        name:null,
        gender:null,
        birth:null,
        height:null,
        weight:null
    });

    function familyInputData_reset(){
        let setData = {
            id:null,
            name:null,
            gender:null,
            birth:null,
            height:null,
            weight:null
        };

        setCustomPopupFamily(null);
        setFamilyInputData(setData);
        setSelId(null);
    }

    function familyData_dataSet(key,val){
		let setData = {...familyInputData}
		setData[key] = val;
		setFamilyInputData(setData);
    }

    function familyOpen(type){
		setTimeout(() => {
			setCustomPopupFamily({
				addClass:"familyDetailCustomPopup",
				title:type === "edit" ? "가족 수정하기" : "가족 등록하기",
                selType:type,
				closeType:true,
				closeFunc:(e) => {
					setTimeout(() => {
						setCustomPopupFamily(null);
						familyInputData_reset();
					}, 200);
				}
			});
		}, 100);
    }

    function familyDelOpen(id){
        setPopupData({
            addClass:null,
            title:"가족 프로필 삭제 확인",
            text:"가족 프로필을 삭제하시겠습니까?<br/>삭제 시 프로필은 복구할 수 없으며 가족 프로필 내 맛저마켓, 케어센터 데이터가 모두 삭제됩니다.",
            closeType:true,
            closeFunc:(e) => {setPopupData(null)},
            btnFunc0:(e)=>{
                setPopupData(null)
            },
            btn0:"취소",
            btn0Type:"line",
            btn1:"확인",
            btn1Type:"",
            btnFunc1:()=>{
                setPopupData(null)
                familyDelFunc(id)
            }
        });
    }

    function familyDelFunc(id){
        if(submitChk){
            setSubmitChk(false);

            fatchSet.FatchApi({
                type:"DELETE",
                loginType:"login",
                url: `/family/profile/delete?user_family_id=${id}`,
                success: (data) => {
                    setFamilyApiUpdate(familyApiUpdate + 1);

                    window.errMsg("가족 프로필 정보가 삭제되었습니다.");
                    familyInputData_reset();

                    setSubmitChk(true);
                },
                err: (data) => {
                    window.errPopupOpen(data);
                    setSubmitChk(true);
                }
            })
        }
    }

    function familyFunc(type){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();
            if(type === "edit"){
                formData.append("user_family_id", familyInputData?.id);
            }
            formData.append("name", familyInputData?.name);
            formData.append("gender", familyInputData?.gender);
            formData.append("birth", familyInputData?.birth);
            formData.append("height", familyInputData?.height);
            formData.append("weight", familyInputData?.weight);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: type === "edit" ? "/family/profile/modify" : "/family/profile/register",
                success: (data) => {
                    setFamilyApiUpdate(familyApiUpdate + 1);
                    familyInputData_reset();

                    if(type === "edit"){
                        window.errMsg("가족 프로필 정보가 수정되었습니다.");
                    }else{
                        window.familyIdSetting(data?.data?.family_id);

                        setPopupData({
                            addClass:null,
                            title:"가족 프로필 등록 완료",
                            text:"가족 프로필 등록이 완료되었습니다!<br/>지금 바로 건강 데이터를 등록해보세요.",
                            closeType:true,
                            closeFunc:(e) => {setPopupData(null);},
                            btnFunc0:(e)=>{
                                setPopupData(null)
                                navigate("/healthSurvey");
                            },
                            btn0:"확인",
                            btn0Type:"",
                            btn1:null,
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }

                    setSubmitChk(true);
                },
                err: (data) => {
                    window.errPopupOpen(data);
                    setSubmitChk(true);
                }
            })
        }
    }

    useEffect(() => {
        if((customPopupFamily?.selType === "edit" ? familyInputData?.id : true) && familyInputData?.name && familyInputData?.gender && familyInputData?.birth && familyInputData?.height && familyInputData?.weight){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [familyInputData]);

    useEffect(() => {
        let setData = {
            id:null,
            name:null,
            gender:null,
            birth:null,
            height:null,
            weight:null
        }

        if(familyDetailData){
            setData.id = familyDetailData?.id ? familyDetailData?.id : null;
            setData.name = familyDetailData?.name ? familyDetailData?.name : null;
            setData.gender = familyDetailData?.gender ? familyDetailData?.gender : null;
            setData.birth = familyDetailData?.birth ? familyDetailData?.birth : null;
            setData.height = familyDetailData?.height ? familyDetailData?.height : null;
            setData.weight = familyDetailData?.weight ? familyDetailData?.weight : null;
        }

        setFamilyInputData(setData);
    }, [familyDetailDataApi]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <MypageContents>
                    <MypageSideMenu tapActive={5}/>
                    <MypageSection>
                        <MypageAreaTitleBox addClass="" title="가족 프로필 관리"/>
                        <div className="familyList">
                            <FamilyItem
                                id={null}
                                type={"familyPageItem"}
                                val={familyId}
                                userType={"my"}
                                name={user?.name}
                                gender={user?.gender === "f" ? "여성" : "남성"}
                                birth={`${user?.birth}년`}
                                height={user?.height}
                                weight={user?.weight}
                                func={()=>{
                                    setFamilyId(null);
                                    window.familyIdSetting("my");
                                }}
                                editFunc={()=>{
                                    navigate("/mypage/editProfile")
                                }}
                            />
                            {familyData && familyData?.map((item,i)=>(
                                <FamilyItem
                                    key={i}
                                    type={"familyPageItem"}
                                    id={item.id}
                                    userType={item.type}
                                    val={familyId}
                                    name={item.name}
                                    gender={item.gender === "f" ? "여성" : "남성"}
                                    birth={`${item.birth}년`}
                                    height={item.height}
                                    weight={item.weight}
                                    func={()=>{
                                        setFamilyId(item.id);
                                        window.familyIdSetting(item.id);
                                    }}
                                    editFunc={()=>{
                                        setSelId(item.id);
                                        familyOpen("edit");
                                    }}
                                />
                            ))}
                            <FamilyItemAddBtn func={()=>{
                                familyOpen("add");
                            }}/>
                        </div>
                    </MypageSection>
                </MypageContents>
            </ContentsSection>
            <Footer/>
            <CustomPopup data={customPopupFamily}>
                <PopupInputBox>
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="이름"
                        placeholder="이름"
                        value={familyInputData?.name}
                        max={null}
                        regexp={null}
                        func={(value)=>{familyData_dataSet("name",value)}}
                    />
                    <InputBoxOnly addClass="" inputName="성별">
                        <InputTap
                            data={[
                                {
                                    id:"m",
                                    name:"남성"
                                },
                                {
                                    id:"f",
                                    name:"여성"
                                }
                            ]}
                            val={familyInputData?.gender}
                            func={(value) => {familyData_dataSet("gender",value)}}
                        />
                    </InputBoxOnly>
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="출생년도"
                        placeholder="출생년도"
                        value={familyInputData?.birth}
                        birthYY={true}
                        max={4}
                        unit={"년"}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{familyData_dataSet("birth",value)}}
                    />
                    <InputBoxCol2>
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="키"
                            placeholder="예 : 165"
                            value={familyInputData?.height}
                            max={3}
                            unit={"cm"}
                            regexp={"number"}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            func={(value)=>{familyData_dataSet("height",value)}}
                        />
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="몸무게"
                            placeholder="예 : 50"
                            value={familyInputData?.weight}
                            max={3}
                            unit={"kg"}
                            regexp={"number"}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            func={(value)=>{familyData_dataSet("weight",value)}}
                        />
                    </InputBoxCol2>
                </PopupInputBox>
                {customPopupFamily?.selType === "edit" ? 
                    <TextBtnItemBox>
                        <TextBtnItem
                            func={()=>{familyDelOpen(familyInputData?.id)}}
                            addClass={"underLine"}
                            contents={"가족 프로필 삭제"}
                        />
                    </TextBtnItemBox>
                :""}
                <BtnBox
                    boxType="fixed"
                    addClass="row2"
                >
                    <BtnItem
                        addClass=""
                        contents={customPopupFamily?.selType === "edit" ? "정보 수정하기" : "가족 등록하기"}
                        disabled={btnChk}
                        func={() => {
							familyFunc(customPopupFamily?.selType);
                        }}
                    />
                    <BtnItem
                        addClass="line"
                        contents="취소"
                        disabled={false}
                        func={() => {
                            setCustomPopupFamily(null);
							familyInputData_reset();
                        }}
                    />
                </BtnBox>
            </CustomPopup>
			<Popup
                data={popupData}
            />
        </>
    );
};

export default MyFamily;