import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { Tap } from "component/basic/tap";
import { MypageArea, MypageAreaTitleBox, MypageContents, MypageSection, MypageSideMenu } from "component/mypage/items";
import PageNation from "component/basic/pageNation";
import { IngredientKeySection, IngredientTable } from "component/mypage/ingredient";

const Ingredient = (props) => {
    const navigate = useNavigate();

    const [submitChk,setSubmitChk] =  useState(true);

    const [ingredientKey,setingredientKey] =  useState(null);
    const [ingredientKeyApi,setingredientKeyApi] =  useState(null);
    const [ingredientPage,setingredientPage] =  useState(1);

    const [ingredientApiUpdate,setIngredientApiUpdate] =  useState(1);

    const ingredientApi = useGet({
        url: ingredientKeyApi ? `/ingredient?keyword=${ingredientKeyApi}&page=${ingredientPage}&numOfRows=10&update=${ingredientApiUpdate}` : null,
        loginType:"login"
    });
    const ingredientApiData = ingredientApi?.data?.ingredients;

    function shFunc(){
        if(ingredientKey !== ingredientKeyApi){
            setingredientPage(1);
        }

        setingredientKeyApi(ingredientKey);
    }

    const [tap,setTap] =  useState(null);

    const userIngredientApi = useGet({
        url: tap ? `/ingredient/user?type=${tap}&user_family_id=${sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : ""}&update=${ingredientApiUpdate}` : null,
        loginType:"login"
    });
    const userIngredientApiData = userIngredientApi?.data?.user_ingredients;
   
    function ingredientDel(id){
        if(submitChk){
            setSubmitChk(false);

            fatchSet.FatchApi({
                type:"DELETE",
                loginType:"login",
                url: `/ingredient/delete?user_ingredient_id=${id}`,
                success: (data) => {
                    setIngredientApiUpdate(ingredientApiUpdate + 1);

                    setSubmitChk(true);
                },
                err: (data) => {
                    window.errPopupOpen(data);
                    setSubmitChk(true);
                }
            })
        }
    }

    function ingredientAdd(item,type){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();
            if(sessionStorage.getItem("familyId")){
                formData.append("user_family_id", sessionStorage.getItem("familyId"));
            }

            formData.append("type", type);

            formData.append("foodCd", item?.food_cd);
            formData.append("foodNm", item?.food_name);
            formData.append("nutConSrtrQua", item?.nutConSrtrQua||100);
            formData.append("enerc", item?.enerc);
            formData.append("chocdf", item?.chocdf);
            formData.append("prot", item?.prot);
            formData.append("fatce", item?.fatce);
            formData.append("sugar", item?.sugar);
            formData.append("nat", item?.nat);
            formData.append("chole", item?.chole);
            formData.append("fasat", item?.fasat);
            formData.append("fatrn", item?.fatrn);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: "/ingredient/register",
                success: (data) => {
                    setIngredientApiUpdate(ingredientApiUpdate + 1);
                    
                    setSubmitChk(true);
                },
                err: (data) => {
                    window.errPopupOpen(data);
                    setSubmitChk(true);
                }
            })
        }
    }

    useEffect(() => {
        
    }, []);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={""}
            >
                <Header
                    subLinkType="market"
                    addClass=""
                    logo={false}
                    mMenu={false}
                    pageBack={true}
                    func={()=>{navigate(-1)}}
                    headTitle={"선호/기피 재료 설정"}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <MypageContents>
                    <MypageSideMenu tapActive={6}/>
                    <MypageSection>
                        <MypageArea>
                            <MypageAreaTitleBox addClass="notBorder" title="선호/기피 재료 설정"/>
                            <IngredientKeySection
                                keyVal={ingredientKey}
                                inputFunc={(value)=>{setingredientKey(value)}}
                                shFunc={()=>{shFunc()}}
                            />
                        </MypageArea>
                        <MypageArea>
                            <Tap
                                addClass="pcType"
                                allText={null}
                                data={[
                                    {
                                        id:null,
                                        name:"재료 검색 결과",
                                    },
                                    {
                                        id:"prefer",
                                        name:"나의 선호재료",
                                    },
                                    {
                                        id:"avoid",
                                        name:"나의 기피재료",
                                    }
                                ]}
                                nameKey="name"
                                valKey="id"
                                val={tap}
                                func={(name,val)=>{setTap(val)}}
                            />
                            <IngredientTable
                                items={tap ? userIngredientApiData : ingredientApiData?.data}
                                tap={tap}
                                delFunc={(id)=>{ingredientDel(id)}}
                                preferFunc={(item,type)=>{ingredientAdd(item,"prefer")}}
                                avoidFunc={(item,type)=>{ingredientAdd(item,"avoid")}}
                                notItemText={tap === "prefer" ? "나의 선호재료가 없습니다." : tap === "avoid" ? "나의 기피재료가 없습니다." : "선호/기피할 재료를 검색해 주세요."}
                            />
                            {ingredientApiData && tap == null ? <PageNation perPage={5} total={ingredientApiData?.last_page} page={ingredientPage} func={(i)=>{setingredientPage(i)}}/> : ""}
                        </MypageArea>
                    </MypageSection>
                </MypageContents>
            </ContentsSection>
            <Footer/>
        </>
    );
};

export default Ingredient;