import { useNavigate } from "react-router-dom";
import { useSwiper } from "swiper/react";

function CardItem(data){
    let navigate = useNavigate();

    function cardNameImg(code){
        let cardName = null;

        if(code == "01"){
            cardName = "bc";
        }
        if(code == "03" || code == "16"){
            cardName = "hana";
        }
        if(code == "07"){
            cardName = "hyundai";
        }
        if(code == "02"){
            cardName = "kb";
        }
        if(code == "08"){
            cardName = "lotte";
        }
        if(code == "12"){
            cardName = "nh";
        }
        if(code == "04"){
            cardName = "samsung";
        }
        if(code == "06"){
            cardName = "shinhan";
        }

        return cardName;
    }

    return (
        <div className={`cardItem ${data.addClass||""} ${cardNameImg(data.code)||""}`}>
            <div className="cardItem_topInfo">
                <div className="cardItem_head">
                    <h1 className="cardName">{cardNameImg(data.code) ? <img src={`/assets/images/card/${cardNameImg(data.code)}.svg`} className="cardNameImg"/> : data.name}</h1>
                    {data.cardDelFunc ? <button type="button" onClick={()=>{data.cardDelFunc()}} className="cardDel">
                        <img src="/assets/images/card/card_del.svg"/>
                    </button> : ""}
                </div>
                <img src="/assets/images/card/sim.svg" className="cardSimImg"/>
            </div>
            <h1 className="cardNumber">{data.cardNumber} - ****</h1>
        </div>
    );
}

function CardAddBtn(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className="cardAddBtn">
            <img src="/assets/images/icon/addIcon.svg"/>
            카드 추가하기
        </button>
    );
}

function CardSlideControll(data){
    const swiper = useSwiper();
    return (
        <div className="cardSlideControll">
            <div className="cardSlideControllBox">
                <button className="swiper-button-prev" onClick={() => swiper.slidePrev()}/>
                <button className="swiper-button-next" onClick={() => swiper.slideNext()}/>
            </div>
        </div>
    );
};

export {CardItem,CardAddBtn,CardSlideControll};