import useGet from "api/useGet";
import { FixedSection } from "component/basic";
import { PointFixedTap } from "component/basic/tap";
import { FilterMoSection, FilterMoTap, FilterSection, FilterTapItem } from "component/product/Filter";
import { OrderFixedItemBox, OrderFixedSubItemBox } from "component/product/order";
import { useNavigate } from "react-router-dom";

function MypageContents(data){
    return (
        <div className={`mypage_contents ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function MypageSection(data){
    return (
        <div className={`mypage_section ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function MypageFullSection(data){
    return (
        <div className={`mypage_full_section ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function MypageArea(data){
    return (
        <div className={`mypage_area ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function MypageBtnBox(data){
    return (
        <div className={`mypageBtnBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function MypageAreaTitleBox(data){
    return (
        <div className={`mypageArea_titleBox ${data.addClass||""}`}>
            {data.title ? <h1 className="mypageArea_title" dangerouslySetInnerHTML={{__html:data.title}}/> : ""}
            {data.children ? 
            <div className="mypageArea_title_subItemBox">
                {data.children}
            </div>
            :""}
        </div>
    );
}

function MypageSideMenu(data){
    const navigate = useNavigate();

    const userApi = useGet({
        url: sessionStorage.getItem("familyId") ? `/family/profile/detail?user_family_id=${sessionStorage.getItem("familyId")}` : `/profile?is_simple=1`,
        loginType:"login"
    });
    const userApiData = sessionStorage.getItem("familyId") ? userApi?.data?.user_family : userApi?.data?.user;

    return (
        <>
            <FixedSection
                pageBox="pageSizing"
                boxAddClass="mypage_fixedsection pcContents"
                addClass="mypage_fixedArea"
                contentsBox="mypage_contents"
                headerChk={true}
                footerChk={true}
                minSize={961}
            >
                <FilterSection>
                    <OrderFixedItemBox
                        name={`<span class='oTextColor'>${userApiData?.name||"고객"}</span> 님,`}
                    >
                        <FilterTapItem
                            addClass=""
                            id={1}
                            val={data.tapActive}
                            name={"주문 내역"}
                            subData={null}
                            func={()=>{navigate("/mypage/order")}}
                        />
                        <FilterTapItem
                            addClass=""
                            id={2}
                            val={data.tapActive}
                            name={"나의 리뷰"}
                            subData={null}
                            func={()=>{navigate("/mypage/review")}}
                        />
                        <FilterTapItem
                            addClass=""
                            id={3}
                            val={data.tapActive}
                            name={"나의 레시피"}
                            subData={null}
                            func={()=>{navigate("/mypage/recipe")}}
                        />
                    </OrderFixedItemBox>
                    <OrderFixedSubItemBox>
                        <FilterTapItem
                            addClass=""
                            id={4}
                            val={data.tapActive}
                            name={"프로필 수정"}
                            subData={null}
                            func={()=>{navigate("/mypage/editProfile")}}
                        />
                        <FilterTapItem
                            addClass=""
                            id={5}
                            val={data.tapActive}
                            name={"가족 프로필 관리"}
                            subData={null}
                            func={()=>{navigate("/mypage/family")}}
                        />
                        <FilterTapItem
                            addClass=""
                            id={6}
                            val={data.tapActive}
                            name={"선호/기피 재료 설정"}
                            subData={null}
                            func={()=>{navigate("/mypage/ingredient")}}
                        />
                        <FilterTapItem
                            addClass=""
                            id={7}
                            val={data.tapActive}
                            name={"식단표"}
                            subData={null}
                            func={()=>{navigate("/mypage/menu")}}
                        />
                    </OrderFixedSubItemBox>
                    <OrderFixedSubItemBox>
                        <FilterTapItem
                            addClass=""
                            id={8}
                            val={data.tapActive}
                            name={"로그아웃"}
                            subData={null}
                            func={()=>{window.logOut()}}
                        />
                    </OrderFixedSubItemBox>
                </FilterSection>
            </FixedSection>
            <FilterMoSection
                addClass={"tapOnly"}
                title={`<span class='oTextColor'>${userApiData?.name||"고객"}</span> 님,`}
                shType={false}
                shValue={null}
                shPlaceholder={null}
                shFunc={(val)=>{}}
                shBtnFunc={()=>{}}
            >
                <FilterMoTap>
                    <PointFixedTap
                        data={[
                            {
                                val:1,
                                name:"주문 내역"
                            },
                            {
                                val:2,
                                name:"나의 리뷰"
                            },
                            {
                                val:3,
                                name:"나의 레시피"
                            },
                            {
                                val:4,
                                name:"프로필 수정"
                            },
                            {
                                val:5,
                                name:"가족 프로필 관리"
                            },
                            {
                                val:6,
                                name:"선호/기피 재료 설정"
                            },
                            {
                                val:7,
                                name:"식단표"
                            },
                            {
                                val:8,
                                name:"로그아웃"
                            }
                        ]}
                        func={(name,val)=>{
                            if(val === 1){
                                navigate("/mypage/order")
                            }else if(val === 2){
                                navigate("/mypage/review")
                            }else if(val === 3){
                                navigate("/mypage/recipe")
                            }else if(val === 4){
                                navigate("/mypage/editProfile")
                            }else if(val === 5){
                                navigate("/mypage/family")
                            }else if(val === 6){
                                navigate("/mypage/ingredient")
                            }else if(val === 7){
                                navigate("/mypage/menu")
                            }else if(val === 8){
                                window.logOut()
                            }
                        }}
                        nameKey={"name"}
                        valKey={"val"}
                        val={data.tapActive}
                        headerChk={true}
                    />
                </FilterMoTap>
            </FilterMoSection>
        </>
    );
}

export {MypageContents,MypageAreaTitleBox,MypageBtnBox,MypageArea,MypageSection,MypageFullSection,MypageSideMenu};