import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, EditerBox, InputChkSection, InputContents } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { ChkBox } from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CustomPopup } from "component/basic/popup";

const JoinTerms = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const type = param.type;

    const termsApi = useGet({
        url:"/terms?type=join",
    });
    const termsData = termsApi?.data?.terms;

    const [checkItems, setCheckItems] = useState([]);
    const [checkItemTitles, setCheckItemTitles] = useState([]);
    const [btnChk,setBtnChk] =  useState(true);
    const [terms,setTerms] =  useState({});
    const [customPopupData,setCustomPopupData] =  useState(null);

    const [marketing,setMarketing] =  useState(false);
    
    // 체크박스 선택
    const handleSingleCheck = (checked, id, text) => {
         if (checked) {
            setCheckItemTitles(prev => [...prev, text]);
            setCheckItems(prev => [...prev, id]);
        } else {
            setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
        const idArr = [],
            textArr = [];
            termsData?.forEach((el) => {idArr.push(el.id);textArr.push(el.term_title)});
            setCheckItemTitles(textArr);
            setCheckItems(idArr);
            setMarketing(true);
        }
        else {
            setCheckItemTitles([]);
            setCheckItems([]);
            setMarketing(false);
        }
    }

    const termsOpen = (id,title,contents) => {
        let termsItem = {
            id:id,
            title:title,
            contents:contents
        }
        setTerms(termsItem);
        setCustomPopupData({
            addClass:"termsCustomPopup",
            title:title,
            closeType:true,
            closeFunc:(e) => {
                setTimeout(() => {
                    setCustomPopupData(null)
                }, 200);
            }
        });
    }

    const joinStert = () => {    
        navigate(`/member/join/${type}/${marketing ? 1 : 0}`);
    }

    useEffect(() => {
        let chkSet = [...checkItems];
        if(chkSet.length == termsData?.length){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [checkItems]);

    return (
        <>
            <ContentsSection
                header={true}
                footer={false}
                mBtn={true}
                pcFooter={true}
            >
                <Header
                    subLinkType="care"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <InputContents
                    title="회원가입 약관 동의"
                    subTitle="회원가입을 진행하기 위해 약관에 동의해주세요."
                >
                    <ChkBox
                        addClass="allChkItem"
                        func={(e) => handleAllCheck(e.target.checked)}
                        checkedType={checkItems?.length === termsData?.length && marketing ? true : false}
                        // chkSubType=""
                        label="전체 동의"
                    />
                    <InputChkSection>
                        {termsData?.map((item, i) => (
                            <ChkBox
                                key={i}
                                addClass="minChk"
                                func={(e) => handleSingleCheck(e.target.checked,item.id,item.title)}
                                checkedType={checkItems.includes(item.id) ? true : false}
                                label={`<span>(필수)</span> ${item.title}`}
                                chkFunc={(e) => termsOpen(item.id,item.title,item.contents)}
                            />
                        ))}
                        <ChkBox
                            addClass="minChk"
                            func={(e) => setMarketing(e.target.checked)}
                            checkedType={marketing}
                            label={`<span>(선택)</span> 마케팅 정보 수신 동의`}
                        />
                    </InputChkSection>
                    <BtnBox
                        boxType="fixed"
                        addClass="pcBasicBtnBox"
                    >
                        <BtnItem
                            addClass=""
                            contents="다음"
                            disabled={btnChk}
                            func={() => {joinStert()}}
                        />
                    </BtnBox>
                </InputContents>
            </ContentsSection>
            <CustomPopup data={customPopupData}>
                <EditerBox addClass="termsText" data={terms?.contents}/>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents="확인하고 동의합니다."
                        disabled={false}
                        func={() => {
                            if(!checkItems.includes(terms?.id)){
                                handleSingleCheck(true,terms?.id,terms?.title);
                            };
                            setTerms({});
                            setTimeout(() => {
                                setCustomPopupData(null)
                            }, 200);
                        }}
                    />
                </BtnBox>
            </CustomPopup>
            <Footer pcOnly={true}/>
        </>
    );
};

export default JoinTerms;