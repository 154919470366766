import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, InputContents } from "component/app/items";
import { Footer } from "component/elements/footer";
import { Header } from "component/elements/header";
import { WelComeBtn } from "component/member/items";
import { getParam } from "js/function";

const WelCome = (props) => {
    const navigate = useNavigate();
    const type = getParam("type");

    //구글
    function googleLoginStart(){
        window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URL}&response_type=code&scope=openid email profile`
    }

    //네이버
    function naverLoginStart() {
        window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=false&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URL}`;
    }

    //카카오
    function kakaoLoginStart() {
        window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code`;
    }

    useEffect(() => {
        if(type){
            let formData = new FormData();
            if(type == "google"){
                formData.append("code", getParam("code"));
            }
            if(type == "naver"){
                formData.append("code", getParam("code"));
            }
            if(type == "kakao"){
                formData.append("code", getParam("code"));
            }

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/${type}/login`,
                success: (data) => {
                    if(data.data.type == "login"){
                        localStorage.setItem("token",data.data.token.access_token);
                        localStorage.setItem("refresh_token",data.data.token.refresh_token);

                        window.logIn();

                        setTimeout(() => {                            
                            if(sessionStorage.getItem("setUrl")){
                                let reUrl = sessionStorage.getItem("setUrl");
                                sessionStorage.removeItem("setUrl");
                                navigate(reUrl);
                            }else{
                                navigate(`/`);
                            }
                        }, 100);
                    }else{
                        sessionStorage.setItem("token",data.data.token.access_token);
                        sessionStorage.setItem("refresh_token",data.data.token.refresh_token);

                        navigate("/member/terms/sns");
                    }
                },
                err: (data) => {
                    window.errPopupOpen(data);
                }
            })
        }
    }, []);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
            >
                <Header
                    subLinkType="care"
                    addClass=""
                    logo={true}
                    mMenu={true}
                    pageBack={false}
                    func={()=>{}}
                    headTitle={null}
                    rightBtnsArr={null}
                    pageClose={false}
                    closeFunc={()=>{}}
                />
                <InputContents
                    title="로그인"
                    subTitle={null}
                >
                    <WelComeBtn
                        img={null}
                        func={() => {navigate("/member/login")}}
                        text="맛있저염 계정으로 로그인"
                    />
                    <WelComeBtn
                        img="kakao"
                        func={() => {kakaoLoginStart()}}
                        text="카카오 계정으로 로그인"
                    />
                    <WelComeBtn
                        img="naver"
                        func={() => {naverLoginStart()}}
                        text="네이버 계정으로 로그인"
                    />
                    <WelComeBtn
                        img="google"
                        func={() => {googleLoginStart()}}
                        text="구글 계정으로 로그인"
                    />
                    <div className="welcome_sns">
                        <p className="welcome_sns_text">아직 맛있저염 회원이 아니신가요?</p>
                    </div>
                    <WelComeBtn
                        addClass="bColor"
                        img={null}
                        func={() => {navigate("/member/terms/normal")}}
                        text="회원가입 시작"
                    />
                    <WelComeBtn
                        addClass="textBtn"
                        img={null}
                        func={() => {navigate(-1)}}
                        text="둘러보기"
                    />
                </InputContents>
            </ContentsSection>
            <Footer pcOnly={true}/>
        </>
    );
};

export default WelCome;